import { DocumentVerificationData, Evaluation } from 'model/Evaluation';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextOne } from 'context';
import { base64StringToBlob } from 'blob-util';
import { Document, Picture } from 'model';
import Api from 'API';
import Webcam from 'react-webcam';
import { useHistory } from 'react-router';
import fileToBase64 from 'utils/fileToBase64';
import bowser from 'bowser';
import { CameraSide } from './types';
import {
  dropZoneMessages,
  explanationText,
  mediaStreamErrorsMessages,
  passportExplanationText,
  selfieExplanationText,
} from './constants';
import { ToMobileButton } from './styles';

const { browser, os, platform } = bowser.parse(window.navigator.userAgent);
const { type } = platform;
export const isMobileDevice = type === 'mobile' || type === 'tablet';
export const selfieConstraints = {
  width: 1080,
  height: 1920,
  facingMode: 'user',
};
export const MEDIA_CONSTRAINTS = isMobileDevice
  ? { width: 1920, height: 1080, facingMode: 'environment' }
  : selfieConstraints;

const notSupportedBrowser =
  (os.name?.toLowerCase() === 'ios' &&
    isMobileDevice &&
    browser.name?.toLocaleLowerCase() === 'chrome') ||
  browser.name?.toLowerCase() === 'internet explorer';

function useLogic(cameraSide: CameraSide) {
  const { goBack, location } = useHistory();
  const { dispatch, state } = useContext(ContextOne);
  const {
    access_token,
    documentTokenFront,
    entityToken,
    externalEntityId,
    evaluationData,
    productionMode,
    currentDocumentType,
  } = state;

  const {
    nameFirst,
    nameLast,
    addressLine1,
    addressLine2,
    addressCountryCode,
    addressPostalCode,
    addressState,
    addressCity,
    birthDate,
  } = evaluationData;

  const [noCamera, setNoCamera] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [outcomeReason, setOutcomeReason] = useState('');
  const rootRef = useRef<HTMLDivElement>(null);
  const webCamRef = useRef<Webcam>(null);
  const documentType = currentDocumentType ?? 'license';

  let cameraTitle;
  if (location.pathname === '/selfie') {
    cameraTitle = 'Selfie time';
  } else if (documentType === 'license') {
    if (cameraSide === 'front') {
      cameraTitle = 'Front side';
    } else if (cameraSide === 'back') {
      cameraTitle = 'Back side';
    }
  }

  const dataToVerify: DocumentVerificationData = {
    name_first: nameFirst,
    name_last: nameLast,
    document_step: 'front',
    document_type: currentDocumentType,
    document_token_front: '',
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_city: addressCity,
    address_state: addressState,
    address_postal_code: addressPostalCode,
    address_country_code: addressCountryCode,
    birth_date: birthDate,
  };

  const handleUserMedia = useCallback(() => {
    if (!rootRef.current) {
      return;
    }
    const video = rootRef.current.querySelector('video:nth-child(2)');
    if (!video) {
      return;
    }
    video.addEventListener(
      'play',
      () => {
        setInProgress(false);
        setOutcomeReason('');
        setTimeout(() => {
          setCameraLoaded(true);
        }, 500);
      },
      { once: true },
    );
  }, [rootRef, setCameraLoaded]);

  const handleCapturePhoto = useCallback(() => {
    if (type === 'mobile' || type === 'tablet') {
      return (webCamRef.current as any).getScreenshot();
    }
    return (webCamRef.current as any).getScreenshot();
  }, [webCamRef]);

  const handleSetContextPicture = useCallback(
    (side: CameraSide, newPicture: Picture) => {
      if (side === 'front') {
        dispatch({ type: 'newPictureFront', payload: newPicture });
      } else {
        dispatch({ type: 'newPictureBack', payload: newPicture });
      }
    },
    [dispatch],
  );

  const getFormDataOfImg = useCallback((newPhotoTaken: string): FormData => {
    const imgData = new FormData();
    const base64Front = newPhotoTaken.replace(
      /^data:image\/[a-z]+;base64,/,
      '',
    );
    const blobFront = base64StringToBlob(base64Front);
    const imgFile = new File([blobFront], 'blob', { type: 'image/png' });
    imgData.append('blob', imgFile);
    return imgData;
  }, []);

  const handleNewPicture = useCallback(
    async (inputFile?: string) => {
      let pictureTaken;
      const newPhotoTaken = inputFile || handleCapturePhoto();
      goBack();
      pictureTaken = {
        url: newPhotoTaken,
        valid: null,
        outcomeReason: '',
        evaluationInProgress: true,
      };
      const img: FormData = getFormDataOfImg(newPhotoTaken);
      if (cameraSide === 'selfie') {
        const base64Selfie = newPhotoTaken.replace(
          /^data:image\/[a-z]+;base64,/,
          '',
        );
        dispatch({ type: 'setSelfieImage', payload: base64Selfie });
        dispatch({ type: 'setSelfieInProgress', payload: true });
        const selfieDescribed: Document = await Api.describeDocument(
          entityToken,
          access_token,
          cameraSide,
          'other',
          nameFirst,
          nameLast,
          productionMode,
        );
        const selfieUploaded: Document = await Api.uploadDocumentImage(
          img,
          entityToken,
          selfieDescribed.document_token,
          access_token,
          'other',
          productionMode,
        );
        dispatch({
          type: 'setSelfieToken',
          payload: selfieUploaded?.document_token,
        });
        dispatch({ type: 'setSelfieInProgress', payload: false });
      } else {
        handleSetContextPicture(cameraSide, pictureTaken);
        const apiDocumentDescribed = await Api.describeDocument(
          entityToken,
          access_token,
          cameraSide,
          currentDocumentType,
          nameFirst,
          nameLast,
          productionMode,
        );

        const apiUploadDocument = await Api.uploadDocumentImage(
          img,
          entityToken,
          apiDocumentDescribed.document_token,
          access_token,
          currentDocumentType,
          productionMode,
          isMobileDevice,
        );

        if (cameraSide === 'front') {
          dataToVerify.document_token_front = apiUploadDocument.document_token;
        } else {
          dataToVerify.document_step = 'back';
          dataToVerify.document_token_front = documentTokenFront;
          dataToVerify.document_token_back = apiUploadDocument.document_token;
        }

        const apiVerifiedDocument: Evaluation = await Api.verifyDocument(
          dataToVerify,
          access_token,
          productionMode,
          entityToken,
          externalEntityId,
        );

        /* If no entity token has been set from init params, we'll use the one from the 1st evaluation call response for the
         * rest of evaluation calls as a header like => Alloy-Entity-Token: <entityToken> */
        if (!entityToken) {
          dispatch({
            type: 'setEntityToken',
            payload: apiVerifiedDocument?.entity_token,
          });
        }

        if (
          apiVerifiedDocument?.summary?.outcome?.toLowerCase() === 'approved'
        ) {
          pictureTaken = {
            url: newPhotoTaken,
            valid: true,
            outcomeReason: apiVerifiedDocument?.summary?.outcome ?? '',
            evaluationInProgress: false,
          };
          handleSetContextPicture(cameraSide, pictureTaken);
          if (cameraSide === 'front') {
            dispatch({
              type: 'setDocumentTokenFront',
              payload: apiVerifiedDocument.supplied.document_token_front,
            });
          } else {
            dispatch({
              type: 'setDocumentTokenBack',
              payload: apiVerifiedDocument.supplied.document_token_back,
            });
          }
        } else {
          pictureTaken = {
            url: newPhotoTaken,
            valid: false,
            outcomeReason: apiVerifiedDocument?.summary?.outcome ?? '',
            evaluationInProgress: false,
          };
          handleSetContextPicture(cameraSide, pictureTaken);
        }
      }
    },
    [
      access_token,
      cameraSide,
      documentTokenFront,
      entityToken,
      externalEntityId,
      dataToVerify,
      dispatch,
      getFormDataOfImg,
      handleCapturePhoto,
      handleSetContextPicture,
      goBack,
      nameFirst,
      nameLast,
      productionMode,
      currentDocumentType,
    ],
  );

  const handleCameraLoad = useCallback(() => {
    if (inProgress) {
      setOutcomeReason('Please allow access to your webcam');
    }
  }, [inProgress]);

  const handleCameraUserMedia = useCallback(() => {
    handleUserMedia();
  }, [handleUserMedia]);

  const handleMediaStreamErrorMessages = useCallback((message: string) => {
    switch (message) {
      case 'DevicesNotFoundError':
      case 'NotFoundError':
        return mediaStreamErrorsMessages.notFound;
      case 'NotReadableError':
      case 'TrackStartError':
        return mediaStreamErrorsMessages.notReadable;
      case 'OverconstrainedError':
      case 'ConstraintNotSatisfiedError':
        return mediaStreamErrorsMessages.overConstrained;
      case 'NotAllowedError':
      case 'PermissionDeniedError':
        return mediaStreamErrorsMessages.noPermissions;
      default:
        return message;
    }
  }, []);

  const handleUserMediaError = useCallback(
    (mediaStreamError: MediaStreamError) => {
      if (!mediaStreamError) {
        return;
      }
      setNoCamera(true);
      setInProgress(false);
      setOutcomeReason(
        handleMediaStreamErrorMessages(mediaStreamError.name ?? ''),
      );
    },
    [handleMediaStreamErrorMessages],
  );

  const handleCameraUserMediaError = useCallback(
    (mediaStreamError: any) => {
      handleUserMediaError(mediaStreamError);
    },
    [handleUserMediaError],
  );

  useEffect(() => {
    if (notSupportedBrowser) {
      setNoCamera(true);
      return;
    }
    handleCameraLoad();
  }, [handleCameraLoad]);

  const onCancelCapture = () => {
    goBack();
  };

  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length === 0) {
        return setOutcomeReason(dropZoneMessages.error);
      }
      if (files.length > 1) {
        return setOutcomeReason(dropZoneMessages.maxFilesExceeded);
      }
      setOutcomeReason('');
      const base64Img = await fileToBase64(files[0]);
      return handleNewPicture(base64Img);
    },
    [handleNewPicture],
  );

  const noCameraExplanationText = isMobileDevice ? (
    <>Please use the input below to attach the image.</>
  ) : (
    <>
      Please use the input below to attach the image or click
      <ToMobileButton variant="link" to="/mobile">
        &nbsp;here&nbsp;
      </ToMobileButton>
      to request a link and proceed with your phone.
    </>
  );

  function takePictureExplanationText() {
    if (noCamera) {
      return noCameraExplanationText;
    }
    if (cameraSide === 'selfie') {
      return selfieExplanationText;
    }
    if (documentType === 'license') {
      return explanationText;
    }
    if (documentType === 'passport') {
      return passportExplanationText;
    }
    return '';
  }

  return {
    type,
    inProgress,
    cameraLoaded,
    outcomeReason,
    rootRef,
    webCamRef,
    handleUserMedia,
    handleCameraLoad,
    handleNewPicture,
    handleCameraUserMedia,
    handleCameraUserMediaError,
    noCamera,
    onDrop,
    onCancelCapture,
    documentType,
    cameraTitle,
    takePictureExplanationText,
  };
}

export default useLogic;
