export const explanationText =
  'Fit your ID card inside the frame and take the picture.';

export const passportExplanationText =
  'Fit your passport open inside the frame and take the picture.';

export const selfieExplanationText =
  'Fit yourself in the frame and take the picture.';

export const dragAndDrop = 'Click or drag and drop your photo ID.';

export const mediaStreamErrorsMessages = {
  notFound: "Device doesn't have a working webcam.",
  notReadable: 'Camera is already being used.',
  overConstrained:
    'Camera is not able to fulfill the constraint requests. Probably due to it being and old device.',
  noPermissions:
    'You need to give the browser permissions to access your webcam.',
};

export const dropZoneMessages = {
  error: 'Something went wrong. Please be sure you are attaching an image.',
  maxFilesExceeded: 'Please, attach only 1 photo.',
};
