import {
  DescribeDocument,
  Document,
  DocumentType,
  DocumentVerificationData,
  Evaluation,
  SmsData,
  UrlShorter,
} from 'model';
import { CameraSide } from 'containers/Main/Camera/types';
import * as querystring from 'querystring';

class Api {
  private baseURL = process.env.REACT_APP_API_URL;

  private parentDomainHeader = document.referrer;

  init = async (clientKey: string) => {
    try {
      const url = `${this.baseURL}/auth/init`;
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          id: clientKey,
        }),
      });
      if (response.ok) {
        return await response.json();
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  getPublicClientInfo = async (clientId: string, access_token: string) => {
    try {
      const url = `${this.baseURL}/client/public/${clientId}`;
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        }),
        method: 'GET',
      });
      if (response.ok) {
        return await response.json();
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  private static buildDocumentName(
    nameFirst?: string,
    nameLast?: string,
  ): string {
    if (nameFirst && nameLast) {
      return `${nameLast}_${nameFirst?.substr(0, 1).toUpperCase() +
        nameFirst.substr(1)}`;
    }
    if (!nameFirst && nameLast) {
      return nameLast;
    }
    if (nameFirst && !nameLast) {
      return nameFirst;
    }
    return 'license_id';
  }

  describeDocument = async (
    entityToken: string,
    access_token: string,
    cameraSide: CameraSide,
    documentType: DocumentType,
    nameFirst?: string,
    nameLast?: string,
    productionMode?: boolean,
  ) => {
    const documentToDescribe: DescribeDocument = {
      name: `${Api.buildDocumentName(nameFirst, nameLast)}_${cameraSide}`,
      extension: 'png',
      type: documentType,
    };
    try {
      const describeUrl = entityToken
        ? `entities/${entityToken}/documents`
        : `documents`;
      const url = `${this.baseURL}/${describeUrl}${
        productionMode ? '?production=true' : ''
      }`;
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(documentToDescribe),
      });
      if (response.ok) {
        return (await response.json()) as Document;
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  uploadDocumentImage = async (
    picture: FormData,
    entityToken: string,
    documentToken: string,
    access_token: string,
    documentType: DocumentType,
    productionMode?: boolean,
    mobileCrop?: boolean,
  ) => {
    try {
      const describeUrl: string = entityToken
        ? `entities/${entityToken}/documents/${documentToken}/${documentType}`
        : `documents/${documentToken}/${documentType}`;
      const parsedUrlQueryInput =
        {
          ...(productionMode && { production: productionMode }),
          ...(mobileCrop && { mobileCrop }),
        } ?? undefined;
      const queryParams =
        Object.keys(parsedUrlQueryInput).length > 0
          ? `?${querystring.stringify(parsedUrlQueryInput as any)}`
          : '';
      const url: string = `${this.baseURL}/${describeUrl}${queryParams}`;
      console.log(url);
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          Authorization: `Bearer ${access_token}`,
        }),
        method: 'POST',
        body: picture,
      });
      if (response.ok) {
        return (await response.json()) as Document;
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  verifyDocument = async (
    documentVerificationData: DocumentVerificationData,
    access_token: string,
    productionMode?: boolean,
    entityToken?: string,
    externalEntityId?: string,
  ) => {
    try {
      const evalHeaders = new Headers({
        'X-Parent-Domain': this.parentDomainHeader,
        'X-Alloy-Sdk-Platform': 'Web',
        'X-Alloy-Sdk-Version': '1',
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json',
        ...(entityToken && { 'Alloy-Entity-Token': entityToken }),
        ...(externalEntityId && {
          'Alloy-External-Entity-ID': externalEntityId,
        }),
      });
      const url = `${this.baseURL}/evaluations${
        productionMode ? '?production=true' : ''
      }`;
      const response = await fetch(url, {
        headers: evalHeaders,
        method: 'POST',
        body: JSON.stringify(documentVerificationData),
      });
      if (response.ok) {
        return (await response.json()) as Evaluation;
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  shortenUrl = async (data: UrlShorter, access_token: string) => {
    try {
      const url = `${this.baseURL}/communications/shortenUrl`;
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return await response.json();
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };

  sendSmsLink = async (smsData: SmsData, access_token: string) => {
    try {
      const url = `${this.baseURL}/communications/sms`;
      const response = await fetch(url, {
        headers: new Headers({
          'X-Parent-Domain': this.parentDomainHeader,
          'X-Alloy-Sdk-Platform': 'Web',
          'X-Alloy-Sdk-Version': '1',
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(smsData),
      });
      if (response.ok) {
        return await response.json();
      }
      return Error(response.statusText);
    } catch (e) {
      return e;
    }
  };
}

const api = new Api();

export default api;
