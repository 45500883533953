import React, { FC } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, ArrowIcon, Button } from './styles';
import useLogic from './logic';
import { Props } from './types';

const BackButton: FC<Props> = ({ to, ...rest }) => {
  const { handleChangeDocument } = useLogic(to);
  return (
    <Container {...rest}>
      <Button onClick={handleChangeDocument} variant="link">
        <ArrowIcon icon={faArrowLeft} />
        Back
      </Button>
    </Container>
  );
};

export default BackButton;
