import React, { FC, useCallback, useContext } from 'react';
import { ContextOne } from 'context';
import { useHistory } from 'react-router';
import { FadeInAnimation } from 'styles/mixins';
import {
  Button,
  ButtonContainer,
  Container,
  Info,
  TipsContainer,
  Title,
  TipCardIcon,
  TipDaylightIcon,
  TipSurroundingsIcon,
  TipSelfieIcon,
  TipRow,
  TipInfo,
  TipTitle,
  TipDescription,
  OptionalRow,
} from './styles';

const Tips: FC = () => {
  const history = useHistory();
  const { state } = useContext(ContextOne);
  const { dataLoaded, allowedDocumentTypes, requiredSelfie } = state;

  const handleNavigation = useCallback(() => {
    if (allowedDocumentTypes?.length > 1) {
      return history.push('/step-1');
    }
    return history.push('/step-2');
  }, [allowedDocumentTypes, history]);

  return (
    <Container>
      {dataLoaded && (
        <>
          <Info>
            <FadeInAnimation duration="0.5s">
              <Title>We have a few tips to make this process easier</Title>
            </FadeInAnimation>
            <TipsContainer requiredSelfie={requiredSelfie}>
              <FadeInAnimation duration="0.5s">
                <TipRow requiredSelfie={requiredSelfie}>
                  <TipCardIcon />
                  <TipInfo>
                    <TipTitle>Check your ID or passport</TipTitle>
                    <TipDescription>
                      Keep your ID close and make sure it is in good condition.
                    </TipDescription>
                  </TipInfo>
                </TipRow>
              </FadeInAnimation>
              <FadeInAnimation margin duration="1s">
                <TipRow requiredSelfie={requiredSelfie}>
                  <TipDaylightIcon />
                  <TipInfo>
                    <TipTitle>Daylight works best</TipTitle>
                    <TipDescription>
                      Choose a room with good (indirect) lighting.
                    </TipDescription>
                  </TipInfo>
                </TipRow>
              </FadeInAnimation>
              <FadeInAnimation duration="1.5s">
                <TipRow requiredSelfie={requiredSelfie}>
                  <TipSurroundingsIcon />
                  <TipInfo>
                    <TipTitle>Mind your surroundings</TipTitle>
                    <TipDescription>
                      The clearer the background of the picture is, the better.
                    </TipDescription>
                  </TipInfo>
                </TipRow>
              </FadeInAnimation>
              <FadeInAnimation margin duration="2s">
                <TipRow requiredSelfie={requiredSelfie}>
                  {requiredSelfie && (
                    <OptionalRow>
                      <TipSelfieIcon />
                      <TipInfo>
                        <TipTitle>Be prepared for a selfie</TipTitle>
                        <TipDescription>
                          This helps us match your face with your photo ID.
                        </TipDescription>
                      </TipInfo>
                    </OptionalRow>
                  )}
                </TipRow>
              </FadeInAnimation>
            </TipsContainer>
          </Info>
          <ButtonContainer requiredSelfie={requiredSelfie}>
            <FadeInAnimation duration="2.5s">
              <Button onClick={handleNavigation}>get started</Button>
            </FadeInAnimation>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default Tips;
