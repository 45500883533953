import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 148 148" {...props}>
    {children}
    <defs>
      <filter
        id="a"
        width="185.3%"
        height="185.3%"
        x="-42.7%"
        y="-29.3%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadOuter1" />
        <feOffset dy="10" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="10"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.934641078 0 0 0 0 0.402351586 0 0 0 0 0.402351586 0 0 0 0.499207823 0"
        />
      </filter>
      <circle id="b" cx="74.5" cy="74.5" r="37.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="148" height="148" fill="#EE6767" opacity=".075" rx="74" />
      <rect
        width="110"
        height="110"
        x="19"
        y="19"
        fill="#EE6767"
        opacity=".075"
        rx="55"
      />
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#EE6767" xlinkHref="#b" />
      <g fill="#FFF">
        <path d="M80.859 63.11a2.08 2.08 0 012.984 0l1.493 1.523a2.186 2.186 0 010 3.046l-6.715 6.853 6.715 6.854a2.186 2.186 0 010 3.046l-1.493 1.523a2.08 2.08 0 01-2.984 0l-6.715-6.853-6.715 6.853a2.08 2.08 0 01-2.984 0l-1.492-1.523a2.186 2.186 0 010-3.046l6.714-6.854-6.714-6.853a2.186 2.186 0 010-3.046l1.492-1.523a2.08 2.08 0 012.984 0l6.715 6.853z" />
      </g>
    </g>
  </svg>
));
