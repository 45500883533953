import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import addAlpha from 'utils/addAlpha';
import { Props as ButtonProps } from './types';

const buttonVariants = css<{ variant: ButtonProps['variant'] }>`
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04375rem;
  padding: 0.75rem 1.315rem;
  -webkit-tap-highlight-color: transparent;
  :disabled {
    opacity: 0.4;
  }
  :not(:disabled) {
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
  ${({ variant }) =>
    (variant === 'primary' &&
      css`
        background: ${({ theme }) => theme.colors.primary};
        svg {
          width: 0.875rem;
        }
        :not(:disabled):hover {
          background: ${({ theme }) => theme.colors.primaryAlt};
        }
      `) ||
    (variant === 'secondary' &&
      css`
        background: ${({ theme }) => theme.colors.secondary};
        svg {
          width: 0.875rem;
        }
        :not(:disabled):hover {
          background: ${({ theme }) => theme.colors.secondaryAlt};
        }
      `) ||
    (variant === 'ghost' &&
      css`
        background: ${({ theme }) => theme.colors.white};
        border: 0.125rem solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        padding: 0.625rem 1.5rem;
        svg {
          width: 0.875rem;
        }
        :not(:disabled):hover {
          background: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};
        }
      `) ||
    (variant === 'white' &&
      css`
        background: ${({ theme }) => theme.colors.white};
        border: 0.125rem solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        padding: 0.625rem 1.5rem;
        svg {
          width: 0.875rem;
        }
        :not(:disabled):hover {
          box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
        }
      `) ||
    (variant === 'link' &&
      css`
        background-color: transparent;
        border: none;
        border-radius: initial;
        color: ${({ theme }) => theme.colors.primary};
        padding: 0;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      `)};
`;

export const ButtonDefault = styled.button`
  ${buttonVariants};
`;

export const Link = styled(DefaultLink)`
  ${buttonVariants};
  text-decoration: none;
`;
