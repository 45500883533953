import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 45 39" {...props}>
    {children}
    <g fill="none" fillRule="evenodd">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M28,7 L41,7 C43.209139,7 45,8.790861 45,11 L45,30 C45,32.209139 43.209139,34 41,34 L11,34 C8.790861,34 7,32.209139 7,30 L7,20"
        transform="translate(-1 4)"
      />
      <g transform="translate(11.2 17.2)">
        <circle
          cx="6.8"
          cy="3.8"
          r="3"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M12.7858325,14.8000002 C12.7858325,12.7327086 13.3844158,9.80000019 6.80000019,9.80000019 C0.215584606,9.80000019 0.814167841,12.7327086 0.814167841,14.8000002"
        />
        <rect
          width="11"
          height="1.5"
          x="16.8"
          y="3.8"
          fill="currentColor"
          rx=".75"
        />
        <rect
          width="9"
          height="1.5"
          x="16.8"
          y="8.8"
          fill="currentColor"
          rx=".75"
        />
      </g>
      <g fillRule="nonzero" stroke="currentColor">
        <path
          strokeWidth="1.5"
          d="M6.02096736,9.41747357 C5.89268359,10.6513987 5.33725671,11.7592096 4.50520113,12.5923163 C3.96065761,13.1375478 3.27896306,13.546388 2.42938098,14.221413 C3.21737077,14.4846028 3.92315825,14.9278294 4.49766503,15.5023369 C5.06904215,16.0737148 5.48155005,16.8045792 6.13068049,17.5347296 C6.40971821,16.7165908 6.88469475,15.9883747 7.49964129,15.4055604 C8.15229798,14.7870063 8.96303247,14.3252549 10.0554908,14.0389557 C8.98439897,13.8159279 8.03202188,13.2668828 7.30555705,12.5003649 C6.47746782,11.6266195 5.94314067,10.4706057 6.01029627,9.41172766 Z"
        />
        <path
          fill="currentColor"
          d="M20.1835491,9.46394147 C20.0306987,9.85503161 19.798327,10.2066616 19.5062957,10.4990617 C19.2276667,10.7780426 18.884471,10.9928152 18.4633384,11.3178869 C18.8560859,11.4715561 19.2089832,11.7048366 19.5020721,11.9979253 C19.7926946,12.2885478 20.0085946,12.6542412 20.3205709,13.0254703 C20.4838673,12.612332 20.7360565,12.2435384 21.0535855,11.9426016 C21.3353183,11.6755904 21.6846477,11.4782997 22.0360579,11.1835505 C21.6186086,11.0204157 21.2460968,10.7666424 20.9426006,10.4464134 C20.6755893,10.1646807 20.4782985,9.8153515 20.1835491,9.46394147 Z"
        />
        <path
          strokeWidth="1.5"
          d="M12.4723472,2.14539571 C12.255945,2.66828652 11.9384544,3.13925877 11.5441917,3.53401945 C11.168333,3.9103528 10.7085894,4.20326726 10.1482005,4.63119233 C10.6718307,4.84860826 11.1433668,5.16685089 11.5382562,5.56174012 C11.9292635,5.95274718 12.223243,6.44126661 12.6335963,6.94023061 C12.8652548,6.38621821 13.210644,5.89086449 13.6406253,5.48335142 C14.0183745,5.12534103 14.4835239,4.85742068 14.9546034,4.47234901 C14.3947108,4.24065471 13.8943544,3.89303766 13.4833501,3.4593732 C13.1253396,3.08162417 12.8574191,2.61647502 12.4723472,2.14539571 Z"
        />
      </g>
    </g>
  </svg>
));
