import React, { FC } from 'react';
import RadioButton from 'components/RadioButton';
import {
  Container,
  Content,
  Title,
  Description,
  DocumentSelectorContainer,
  Button,
  ButtonContainer,
  PassportIcon,
  LicenseIcon,
} from './styles';
import useLogic from './logic';

const DocumentSelection: FC = () => {
  const { documentType, handleCheckboxGroup, handleNavigation } = useLogic();

  return (
    <Container>
      <Content>
        <Title>Document type selection</Title>
        <Description>
          Select between license or passport scanning.
          <br /> Keep the document close and make sure is in good condition.
        </Description>
        <DocumentSelectorContainer>
          <RadioButton
            id="passport"
            label="Passport"
            value="passport"
            name="documentType"
            active={documentType === 'passport'}
            onChange={handleCheckboxGroup}
          >
            <PassportIcon />
          </RadioButton>
          <RadioButton
            id="license"
            label="ID card"
            value="license"
            name="documentType"
            active={documentType === 'license'}
            onChange={handleCheckboxGroup}
          >
            <LicenseIcon />
          </RadioButton>
        </DocumentSelectorContainer>
        <ButtonContainer>
          <Button onClick={handleNavigation} disabled={!documentType}>
            continue
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default DocumentSelection;
