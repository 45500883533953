import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { CheckIcon as DefaultCheckIcon } from 'components/Vectors';

export const Container = styled.label<{ active?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray200};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  height: 4rem;
  padding: 0 0.75rem;
  transition: all 0.2s;
  width: 12.5rem;
  &:hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.primary, 0.07)};
    border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => addAlpha(theme.colors.primary, 0.07)};
      border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
    `}
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
`;

export const Input = styled.input`
  cursor: pointer;
  margin-left: auto;
  height: 1.25rem;
  width: 1.25rem;
`;

export const Label = styled.span`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0 0.75rem;
  text-align: left;
`;

export const CheckIcon = styled(DefaultCheckIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 1.25rem;
  position: absolute;
  top: 60%;
  right: 0.2rem;
  transform: translateY(-50%);
  width: 1.25rem;
`;
