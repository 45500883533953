import Bowser from 'bowser';
import { RefObject, useCallback, useContext, useEffect, useState } from 'react';
import getOriginFromQuery from 'utils/getOriginFromQuery';
import { ContextOne } from 'context';
import { useHistory } from 'react-router';
import Api from 'API';
import { DocumentVerificationData, Evaluation, IframeInfo } from 'model';

function useLogic(
  query: string,
  webSocket: SocketIOClient.Socket | undefined,
  bottomRef: RefObject<HTMLDivElement>,
) {
  const { push } = useHistory();
  const { state, dispatch } = useContext(ContextOne);
  const {
    access_token,
    documentTokenFront,
    documentTokenBack,
    evaluationData,
    entityToken,
    externalEntityId,
    mobileFlowInProgress,
    sessionId,
    pictureFront,
    pictureBack,
    productionMode,
    maxEvaluationAttempts,
    currentEvaluationTry,
    currentDocumentType,
    requiredSelfie,
    selfieToken,
  } = state;

  const {
    nameFirst,
    nameLast,
    addressLine1,
    addressLine2,
    addressCountryCode,
    addressPostalCode,
    addressState,
    addressCity,
    birthDate,
  } = evaluationData;

  const validFrontPicture = pictureFront.valid;
  const validBackPicture = pictureBack.valid;
  const pictureTakingFinished =
    (currentDocumentType === 'passport'
      ? validFrontPicture
      : validFrontPicture && validBackPicture) ?? false;

  const fromMobile = !!getOriginFromQuery(query);
  const [isMobile, setIsMobile] = useState<undefined | boolean>(undefined);
  const [finalValidationInProgress, setFinalValidationInProgress] = useState(
    false,
  );
  const [mobileFlowSuccess, setMobileFlowSuccess] = useState<
    undefined | boolean
  >(undefined);

  const dataToVerify: DocumentVerificationData = {
    name_first: nameFirst,
    name_last: nameLast,
    document_step: 'front',
    document_type: currentDocumentType,
    document_token_front: '',
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_city: addressCity,
    address_state: addressState,
    address_postal_code: addressPostalCode,
    address_country_code: addressCountryCode,
    birth_date: birthDate,
    ...(requiredSelfie && { document_token_selfie: selfieToken }),
  };

  useEffect(() => {
    if (validFrontPicture && validBackPicture) {
      bottomRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [validFrontPicture, validBackPicture, bottomRef]);

  const enableMobileFlow = useCallback(() => {
    if (webSocket && !mobileFlowInProgress) {
      webSocket.emit('handleMobileFlow', {
        room: sessionId,
        enabled: true,
      });
    }
  }, [mobileFlowInProgress, sessionId, webSocket]);

  const handleLoad = useCallback(() => {
    if (isMobile === undefined) {
      const deviceType = Bowser.parse(window.navigator.userAgent).platform.type;
      setIsMobile(deviceType === 'mobile');
    }

    if (fromMobile && mobileFlowSuccess === undefined) {
      enableMobileFlow();
    }
  }, [enableMobileFlow, fromMobile, isMobile, mobileFlowSuccess]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const onCancelMobileFlow = useCallback(() => {
    setMobileFlowSuccess(false);
    return (
      webSocket &&
      webSocket.emit('handleMobileFlow', {
        room: sessionId,
        enabled: false,
      })
    );
  }, [sessionId, webSocket]);

  const setIframeInfo = useCallback(
    (apiVerifiedDocument: Evaluation) => {
      const iframeInfo: IframeInfo = {
        documentTokenFront:
          apiVerifiedDocument?.supplied?.document_token_front ?? '',
        documentTokenBack:
          apiVerifiedDocument?.supplied?.document_token_back ?? '',
        evaluationToken: apiVerifiedDocument?.evaluation_token,
        entityToken: apiVerifiedDocument?.entity_token,
        outcome: apiVerifiedDocument?.summary?.outcome,
      };
      dispatch({ type: 'setIframeInfo', payload: iframeInfo });
    },
    [dispatch],
  );

  const handleRetakeImages = useCallback(
    (outcomeReasons: string[]) => {
      outcomeReasons.map(reason => {
        if (reason.startsWith('front')) {
          return dispatch({
            type: 'newPictureFront',
            payload: {
              valid: false,
              outcomeReason: reason.substr(reason.indexOf(' ') + 1),
            },
          });
        }
        return dispatch({
          type: 'newPictureBack',
          payload: {
            valid: false,
            outcomeReason: reason.substr(reason.indexOf(' ') + 1),
          },
        });
      });
    },
    [dispatch],
  );

  const onHandleFinalValidation = useCallback(async () => {
    dispatch({
      type: 'setCurrentEvaluationTry',
      payload: currentEvaluationTry + 1,
    });
    const maxAttemptsReached: boolean =
      currentEvaluationTry >= maxEvaluationAttempts;
    setFinalValidationInProgress(true);
    dataToVerify.document_step = 'final';
    dataToVerify.document_token_front = documentTokenFront;
    dataToVerify.document_token_back = documentTokenBack;
    const apiVerifiedDocument: Evaluation = await Api.verifyDocument(
      dataToVerify,
      access_token,
      productionMode,
      entityToken,
      externalEntityId,
    );
    const outcome = apiVerifiedDocument?.summary?.outcome?.toLowerCase();
    const outcomeReasons = apiVerifiedDocument?.summary?.outcome_reasons?.map(
      (reason: string) => reason.toLowerCase(),
    );
    setIframeInfo(apiVerifiedDocument);
    setFinalValidationInProgress(false);

    switch (outcome) {
      case 'retake images':
        handleRetakeImages(outcomeReasons);
        return push(`/result${query}`, {
          resultOutcome: outcome,
          maxAttemptsReached,
        });
      case 'approved':
      case 'manual review':
      default:
        return push(`/result${query}`, {
          resultOutcome: outcome,
          maxAttemptsReached,
        });
    }
  }, [
    access_token,
    dispatch,
    dataToVerify,
    query,
    documentTokenBack,
    documentTokenFront,
    entityToken,
    externalEntityId,
    push,
    setIframeInfo,
    handleRetakeImages,
    productionMode,
    maxEvaluationAttempts,
    currentEvaluationTry,
  ]);

  const onHandleBackToFlow = useCallback(() => {
    setMobileFlowSuccess(undefined);
  }, []);

  return {
    isMobile,
    fromMobile,
    mobileFlowSuccess,
    onCancelMobileFlow,
    onHandleFinalValidation,
    onHandleBackToFlow,
    finalValidationInProgress,
    currentDocumentType,
    pictureTakingFinished,
  };
}

export default useLogic;
