import getOriginFromQuery from 'utils/getOriginFromQuery';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { ContextOne } from 'context';
import { AlloyLogo, LogoRight } from './styles';

function useLogic(landing?: boolean) {
  const { state } = useContext(ContextOne);
  const history = useHistory();
  const fromMobile = !!getOriginFromQuery(history.location.search);
  const { clientInfo } = state;
  const { logo_left, logo_right } = clientInfo;

  function renderRightLogo() {
    if (clientInfo.set && !logo_right) {
      return <AlloyLogo mode={landing ? 'normal' : 'dark'} />;
    }
    if (clientInfo.set && !landing) {
      return <LogoRight src={logo_right} alt="logoRight" />;
    }
    return '';
  }

  return {
    fromMobile,
    logoLeft: logo_left,
    renderRightLogo,
  };
}

export default useLogic;
