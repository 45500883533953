import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 72 68" {...props}>
    {children}
    <g fill="#F8F9FD" fillRule="evenodd">
      <path d="M49.224 11.826c3.99 0 7.236 3.198 7.345 7.185l.002.206v2.957c0 .816-.657 1.478-1.469 1.478-.775 0-1.41-.603-1.465-1.368l-.004-.11v-2.957c0-2.393-1.885-4.344-4.243-4.431l-.166-.003h-23.51c-2.379 0-4.318 1.896-4.405 4.268l-.003.166V60.61c0 2.393 1.885 4.344 4.243 4.431l.165.003h23.51c2.38 0 4.319-1.896 4.406-4.268l.003-.166v-9.24c0-.816.658-1.478 1.469-1.478.775 0 1.41.603 1.465 1.368l.004.11v9.24c0 4.013-3.178 7.279-7.14 7.388l-.207.003h-23.51c-3.989 0-7.235-3.198-7.344-7.184l-.003-.207V19.217c0-4.013 3.18-7.279 7.142-7.388l.205-.003h23.51zm6.613 15.522c2.58 0 4.771 1.672 5.562 3.998l8.702-2.675A1.468 1.468 0 0172 30.084V42.35a1.474 1.474 0 01-1.899 1.414l-8.515-2.617c-.564 2.673-2.924 4.68-5.75 4.68h-11.02c-3.246 0-5.877-2.648-5.877-5.914v-6.652c0-3.266 2.631-5.913 5.877-5.913h11.02zm0 2.956h-11.02a2.947 2.947 0 00-2.936 2.81l-.003.147v6.652a2.949 2.949 0 002.792 2.953l.146.004h11.02a2.947 2.947 0 002.936-2.81l.004-.147v-6.652a2.949 2.949 0 00-2.793-2.953l-.146-.004zm13.224 1.778l-7.347 2.258v3.754l7.347 2.258v-8.27zM34.531 0c4.057 0 7.347 3.31 7.347 7.391 0 .817-.658 1.479-1.47 1.479-.811 0-1.47-.662-1.47-1.479 0-2.393-1.884-4.344-4.242-4.431l-.165-.003H7.347c-2.38 0-4.318 1.896-4.405 4.268l-.003.166v16.261c0 2.394 1.885 4.344 4.243 4.432l.165.003h5.51c.812 0 1.47.662 1.47 1.478 0 .78-.6 1.418-1.36 1.474l-.11.004h-5.51c-3.989 0-7.235-3.197-7.344-7.184L0 23.652V7.392C0 3.377 3.179.111 7.141.001L7.347 0H34.53z" />
      <path d="M37.043 56.054A2.965 2.965 0 0140 59.027 2.965 2.965 0 0137.043 62a2.965 2.965 0 01-2.956-2.973 2.965 2.965 0 012.956-2.973zM12.87 7a2 2 0 012 2v6.405a2 2 0 01-2 2H8a2 2 0 01-2-2V9a2 2 0 012-2h4.87z" />
    </g>
  </svg>
));
