import styled, { css } from 'styled-components';
import DefaultButton from 'components/Button';
import {
  SuccessScreenIcon as DefaultSuccessIcon,
  DeniedScreenIcon as DefaultDeniedIcon,
  WarningScreenIcon as DefaultWarningIcon,
} from 'components/Vectors';
import addAlpha from 'utils/addAlpha';
import { container } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1<{ outcome: string }>`
  font-size: 1.3125rem;
  line-height: 1.5rem;
  margin: 0 auto 0.5rem;
  max-width: 25rem;
  text-align: center;

  ${({ outcome }) =>
    (outcome &&
      (outcome === 'approved' || outcome === 'manual review') &&
      css`
        color: ${({ theme }) => theme.colors.success};
      `) ||
    (outcome &&
      outcome === 'retake images' &&
      css`
        color: ${({ theme }) => theme.colors.warning};
      `) ||
    (outcome &&
      outcome === 'denied' &&
      css`
        color: ${({ theme }) => theme.colors.error};
      `)};
`;

export const Description = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.5)};
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.25rem;
`;

export const Button = styled(DefaultButton)``;

export const IconContainer = styled.div`
  margin-bottom: 3.125rem;
`;

const commonIconSize = css`
  height: 9.25rem;
  width: 9.25rem;
`;

export const SuccessIcon = styled(DefaultSuccessIcon)`
  ${commonIconSize};
`;
export const DeniedIcon = styled(DefaultDeniedIcon)`
  ${commonIconSize};
`;
export const WarningIcon = styled(DefaultWarningIcon)`
  ${commonIconSize};
`;
