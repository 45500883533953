import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 59 59" {...props}>
    {children}
    <g fill="#4A61F2" fillRule="nonzero">
      <path d="M29.5 11a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".722" />
      <path d="M12.5 18a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".617" />
      <path d="M53.5 35a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".12" />
      <path d="M5.5 35a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".516" />
      <path d="M12.5 52a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".418" />
      <path d="M46.5 52a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".215" />
      <path d="M29.5 59a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" opacity=".32" />
    </g>
  </svg>
));
