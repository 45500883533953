import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 80 80" {...props}>
    {children}
    <g stroke="#69CC8B" strokeWidth="6" fill="none" fillRule="evenodd">
      <circle cx="40" cy="40" r="37" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23 41l11 11 24-24"
      />
    </g>
  </svg>
));
