import React, { FC, useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import Tips from 'components/Tips';
import NotFound from 'components/NotFound';
import Expired from 'components/Expired';
import { ContextOne } from 'context';
import DocumentSelection from './DocumentSelection';
import { Container, Content, Header } from './styles';
import Scan from './Scan';
import Camera from './Camera';
import Mobile from './Mobile';
import QrCode from './QrCode';
import Result from './Result';
import { Props } from './types';
import useLogic from './logic';

const Main: FC<Props> = ({ location: { search: query, pathname } }) => {
  const { socket } = useLogic(query);
  const { state } = useContext(ContextOne);
  const { allowedDocumentTypes } = state;

  return (
    <Container>
      <Header
        pathname={pathname}
        landing={pathname === '/' || pathname === '/expired'}
        logo="Logo"
        enabled={!pathname.includes('camera') && !pathname.includes('selfie')}
      />
      <Content>
        <Switch>
          <Route exact path="/" component={Tips} />
          <Route
            exact
            path="/step-1"
            render={() =>
              allowedDocumentTypes.length > 1 ? (
                <DocumentSelection />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/step-2"
            render={props => <Scan {...props} webSocket={socket} />}
          />
          <Route
            exact
            path="/example"
            render={props => <Scan {...props} example />}
          />
          <Route
            exact
            path="/camera-front"
            render={props => <Camera {...props} cameraSide="front" />}
          />
          <Route
            exact
            path="/camera-back"
            render={props => <Camera {...props} cameraSide="back" />}
          />
          <Route
            exact
            path="/selfie"
            render={props => <Camera {...props} cameraSide="selfie" />}
          />
          <Route
            path="/result"
            render={props => <Result {...props} webSocket={socket} />}
          />
          <Route path="/expired" component={Expired} />
          <Route exact path="/mobile" component={Mobile} />
          <Route
            exact
            path="/qrCode"
            render={props => <QrCode {...props} webSocket={socket} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Content>
    </Container>
  );
};

export default withRouter(Main);
