import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 40 30" {...props}>
    {children}
    <g fill="none" fillRule="evenodd">
      <rect
        width="38.5"
        height="28.5"
        x=".75"
        y=".75"
        fill="#FFF"
        stroke="#4A61F2"
        strokeWidth="1.5"
        rx="4"
      />
      <g transform="translate(6 8)">
        <circle cx="6" cy="3" r="3" stroke="#4A61F2" strokeWidth="1.5" />
        <path
          stroke="#4A61F2"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M11.986 14c0-2.067.598-5-5.986-5S.014 11.933.014 14"
        />
        <rect width="11" height="1.5" x="17" y="3" fill="#4A61F2" rx=".75" />
        <rect width="9" height="1.5" x="17" y="8" fill="#4A61F2" rx=".75" />
      </g>
    </g>
  </svg>
));
