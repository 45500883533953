import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 30 40" {...props}>
    {children}
    <g fill="none" fillRule="evenodd" stroke="#4A61F2" strokeWidth="1.5">
      <rect width="28.5" height="38.5" x=".75" y=".75" fill="#FFF" rx="4" />
      <circle cx="15" cy="15" r="8.25" />
      <path d="M13 7c-.667 2.667-1 5.333-1 8s.333 5.333 1 8M17 7c.667 2.667 1 5.333 1 8s-.333 5.333-1 8" />
      <path d="M7 13c2.667-.667 5.333-1 8-1s5.333.333 8 1M7 17c2.667.667 5.333 1 8 1s5.333-.333 8-1" />
      <path strokeLinecap="round" d="M8 29.5h14M11 33.5h8" />
    </g>
  </svg>
));
