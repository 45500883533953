import React, { createContext, ReactNode, useReducer } from 'react';
import { defaultPicture, defaultIframeInfo } from 'model';
import { Action, initialState, State } from './types';

const ContextOne = createContext<{ state: State; dispatch: any }>({
  state: initialState,
  dispatch: undefined,
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setAccessToken':
      return { ...state, access_token: action.payload };
    case 'setSessionId':
      return { ...state, sessionId: action.payload };
    case 'setClientKey':
      return { ...state, clientKey: action.payload };
    case 'newPictureFront':
      return {
        ...state,
        pictureFront: { ...state.pictureFront, ...action.payload },
      };
    case 'newPictureBack':
      return {
        ...state,
        pictureBack: { ...state.pictureBack, ...action.payload },
      };
    case 'setEntityToken':
      return { ...state, entityToken: action.payload };
    case 'setExternalEntityId':
      return { ...state, externalEntityId: action.payload };
    case 'setDocumentTokenFront':
      return { ...state, documentTokenFront: action.payload };
    case 'setDocumentTokenBack':
      return { ...state, documentTokenBack: action.payload };
    case 'setMobileFlowInProgress':
      return { ...state, mobileFlowInProgress: action.payload };
    case 'setIframeInfo':
      return { ...state, iframeInfo: action.payload };
    case 'setEvaluationData':
      return {
        ...state,
        evaluationData: { ...state.evaluationData, ...action.payload },
      };
    case 'resetContext':
      return {
        ...state,
        pictureFront: defaultPicture,
        pictureBack: defaultPicture,
        documentTokenFront: '',
        documentTokenBack: '',
        iframeInfo: defaultIframeInfo,
        selfieToken: '',
      };
    case 'setClientInfo':
      return {
        ...state,
        clientInfo: { ...state.clientInfo, ...action.payload },
      };
    case 'toggleProductionMode':
      return {
        ...state,
        productionMode: action.payload,
      };
    case 'setMaxEvaluationAttempts':
      return {
        ...state,
        maxEvaluationAttempts: action.payload,
      };
    case 'setCurrentEvaluationTry':
      return {
        ...state,
        currentEvaluationTry: action.payload,
      };
    case 'setAllowedDocumentTypes':
      return {
        ...state,
        allowedDocumentTypes: action.payload,
      };
    case 'setCurrentDocumentType':
      return {
        ...state,
        currentDocumentType: action.payload,
      };
    case 'setRequiredSelfie':
      return { ...state, requiredSelfie: action.payload };
    case 'setSelfieToken':
      return { ...state, selfieToken: action.payload };
    case 'setDataLoaded':
      return { ...state, dataLoaded: action.payload };
    case 'setSelfieInProgress':
      return { ...state, selfieInProgress: action.payload };
    case 'setSelfieImage':
      return { ...state, selfieImage: action.payload };
    default:
      return initialState;
  }
};

type UseReducer = (prevState: State, action: Action) => State;
function ContextOneProvider(props: { children?: ReactNode }) {
  const [state, dispatch] = useReducer<UseReducer>(
    reducer,
    initialState,
    undefined,
  );
  const value = { state, dispatch };
  const { children } = props;

  return <ContextOne.Provider value={value}>{children}</ContextOne.Provider>;
}

export { ContextOne, ContextOneProvider };
