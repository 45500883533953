import React, { FC } from 'react';
import QRCode from 'react-qr-code';
import {
  Button,
  Container,
  Content,
  LoadingContent,
  QrContent,
  Section,
  SpinnerIcon,
  Text,
  Title,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const QrCode: FC<Props> = ({ webSocket }) => {
  const {
    url,
    sessionId,
    mobileFlowEnabled,
    handleCancelMobileFlow,
  } = useLogic(webSocket);

  return (
    <Container>
      <Content>
        {mobileFlowEnabled ? (
          <LoadingContent>
            <SpinnerIcon />
            <Text>Mobile flow now in progress...</Text>
          </LoadingContent>
        ) : (
          <Section>
            <Title>Now you can scan the QR code below</Title>
            <QrContent>
              {sessionId && <QRCode size={200} value={url} />}
            </QrContent>
            {mobileFlowEnabled ? (
              <Button
                type="button"
                variant="link"
                onClick={handleCancelMobileFlow}
              >
                Back to desktop
              </Button>
            ) : (
              <Button type="button" variant="link" to="/mobile">
                Cancel
              </Button>
            )}
          </Section>
        )}
      </Content>
    </Container>
  );
};

export default QrCode;
