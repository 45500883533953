import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/Button';
import addAlpha from 'utils/addAlpha';
import { loadingIcon } from 'styles/mixins';
import { TagType } from './types';

export const Container = styled.div<{ type: TagType }>`
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${({ type }) =>
    (type === 'accepted' &&
      css`
        background-color: ${({ theme }) => theme.colors.success};
        padding: 0.5625rem;
      `) ||
    (type === 'checking' &&
      css`
        background-color: ${({ theme }) => theme.colors.primary};
        padding: 0.5625rem;
      `)}
`;

export const SuccessIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const InfoIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const CheckingIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
  ${loadingIcon};
`;

export const OutcomeReasonContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  * + * {
    margin-left: 0.5rem;
  }
`;

export const OutcomeReason = styled.span`
  font-size: 0.75rem;
  letter-spacing: 0.046875rem;
  line-height: 1rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const RejectedPhoto = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem;
`;

export const Button = styled(DefaultButton)`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 1rem;
  text-transform: none;
`;

export const TagLabel = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.046875rem;
  line-height: 1rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
`;
