import styled, {
  BaseThemedCssFunction,
  css,
  DefaultTheme,
  keyframes,
} from 'styled-components';
import keysOf from 'utils/keysOf';

// MEDIA QUERIES
const size = {
  mobile: '375px',
  mobileXL: '480px',
  tablet: '768px',
  tabletH: '990px',
  laptop: '1024px',
} as const;

const minWidthQuery = (width: string) => `@media (min-width: ${width})`;

const minWidth = (width: string): BaseThemedCssFunction<DefaultTheme> => (
  // tslint:disable:no-any
  first: any,
  ...args: any
) =>
  css`
    ${minWidthQuery(width)} {
      ${css(first, ...args)};
    }
  `;

interface Breakpoint extends ReturnType<typeof minWidth> {
  query: string;
}

export const from = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = minWidth(size[key]) as Breakpoint;
      mixin.query = minWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);

export const container = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const title = css`
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin: 0 auto;
  text-align: center;
`;

export const text = css`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
`;

export const input = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.inputBorder};
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 1rem 1.75rem 1rem 1.75rem;
  -webkit-appearance: none;
  ::placeholder {
    opacity: 0.4;
    text-align: center;
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.inputFocusBg};
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  :disabled {
    opacity: 0.4;
  }
`;

export const loadingAnimation = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 2s linear infinite;
`;

export const loadingIcon = css<{ checking?: string }>`
  ${loadingAnimation};

  ${({ checking }) =>
    checking === 'true' &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const FadeInAnimation = styled.div<{
  duration: string;
  margin?: boolean;
  secondRow?: boolean;
}>`
  animation-name: ${fadeIn};
  animation-duration: ${({ duration }) =>
    (duration &&
      css`
        ${duration}
      `) ||
    '1s'};
  ${({ margin }) =>
    margin &&
    css`
      ${from.tablet`margin-left: auto;`}
    `};
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
`;
