import styled, { css } from 'styled-components';
import DefaultButton from 'components/Button';
import { container, from, title } from 'styles/mixins';
import {
  CardIdIcon,
  DaylightIcon,
  SelfieIcon,
  SurroundingsIcon,
} from 'components/Vectors';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  ${container};
`;

export const Info = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${title};
  margin: 2.5rem 0 0;
  max-width: 18rem;
  padding: 0 1.5rem;
`;

export const TipsContainer = styled.div<{ requiredSelfie?: boolean }>`
  margin-top: 2.75rem;
  ${({ requiredSelfie }) =>
    requiredSelfie
      ? css`
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 3.75rem;
          padding: 0 2.5rem;
          > :not(:first-child) {
            margin-top: 3rem;
          }
          ${from.tablet`
            > :nth-child(2) {
                margin-top: 0;
            }
          `}
          ${from.mobileXL`
            padding: 0 4.6875rem;
            > :nth-child(n + 3) {
                margin-top: 3rem;
            }
          `}
        `
      : css`
          padding: 0 2.5rem;
          > * + * {
            margin-top: 2.75rem;
          }
        `};
`;

export const TipRow = styled.div<{ requiredSelfie?: boolean }>`
  align-items: flex-start;
  display: flex;
  ${({ requiredSelfie }) =>
    requiredSelfie &&
    css`
      ${from.tablet`
        max-width: 18rem;
      `};
    `};
`;

export const OptionalRow = styled.div`
  display: flex;
`;

export const TipInfo = styled.div`
  align-items: flex-start;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  letter-spacing: 0;
  margin-left: 1rem;
`;

export const TipTitle = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  line-height: 1rem;
`;

export const TipDescription = styled.span`
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-top: 0.375rem;
  opacity: 0.68;
`;

export const ButtonContainer = styled.div<{ requiredSelfie?: boolean }>`
  display: flex;
  justify-content: center;
  margin: 3.5rem auto;
  ${from.tablet`
    margin: auto auto 3.5rem;
  `};
`;

export const Button = styled(DefaultButton)``;

const commonIconStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  filter: brightness(1.5);
  min-width: 3.25rem;
`;

export const TipCardIcon = styled(CardIdIcon)`
  ${commonIconStyles};
  height: 2.375rem;
`;
export const TipDaylightIcon = styled(DaylightIcon)`
  ${commonIconStyles};
  height: 2.75rem;
`;
export const TipSurroundingsIcon = styled(SurroundingsIcon)`
  ${commonIconStyles};
  height: 2.625rem;
`;
export const TipSelfieIcon = styled(SelfieIcon)`
  ${commonIconStyles};
  height: 2rem;
`;
