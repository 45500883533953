import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 255 160" {...props}>
    {children}
    <g fill="none" fillRule="evenodd">
      <rect width="255" height="160" fill="#FFF" rx="12" />
      <g fill="#4A61F2">
        <g fillOpacity=".07">
          <path d="M190.756 89.885c25.152 0 47.898 10.214 64.25 26.685l-.002 31.436c0 6.628-5.373 12-12 12l-140.296-.001c8.883-40.106 44.93-70.12 88.048-70.12z" />
          <path d="M255.003 50.792v97.214c0 6.628-5.372 12-12 12h-47.245c-5.424-10.345-8.488-22.1-8.488-34.566 0-38.786 29.67-70.7 67.733-74.648z" />
        </g>
        <path
          fillRule="nonzero"
          d="M78.83 22.938l.228.003c4.078.118 7.363 3.374 7.481 7.418l.003.225v59.47l-.003.225c-.105 3.591-2.707 6.562-6.146 7.264-1.165-6.1-5.77-9.697-13.617-10.741l-.389-.05.02-.061-.169-.054c-.539-.187-.86-.445-1.016-.77-.109-.227-.207-.934-.269-1.92l-.024-.439-.01-.23-.015-.386-.01-.381-.008-.386-.009-.81v-.946l.003-.345c.031-1.307-1.901-1.44-6.614-1.864 2.285 0 4.692-.607 7.222-1.821 2.732-1.833 4.757-4.78 6.073-8.817a32.616 32.616 0 001.099-4.505l.102-.616.363-1.518.218-.29c3.043-4.104 3.774-6.38 3.774-10.237 0-5.794-1.944-10.213-5.917-11.83l-.328-.127-.07-.075c-5.499-5.985-13.598-7.56-22.248-4.558-8.263 2.867-12.842 9.512-14.643 21.28l-.095.64-.14.106c-3.214 2.41-5.855 4.724-7.972 7.036l.002-32.244.003-.225c.118-4.044 3.403-7.3 7.481-7.418l.227-.003h45.414zm88.257 40.78c.946 0 1.713.76 1.713 1.698 0 .939-.767 1.7-1.713 1.7h-65.121c-.947 0-1.714-.761-1.714-1.7 0-.938.767-1.699 1.714-1.699h65.12zm-13.71-13.594c.946 0 1.714.76 1.714 1.7 0 .938-.768 1.698-1.714 1.698h-51.411c-.947 0-1.714-.76-1.714-1.699 0-.938.767-1.699 1.714-1.699h51.411zm27.42-20.39c1.892 0 3.427 1.522 3.427 3.399s-1.535 3.398-3.428 3.398H103.68c-1.892 0-3.427-1.521-3.427-3.398 0-1.877 1.535-3.398 3.427-3.398h77.117z"
          opacity=".066"
        />
        <path
          fillRule="nonzero"
          d="M48.554 33.766c8.65-3.002 16.75-1.427 22.248 4.558l.07.075.096.036.232.09c3.973 1.618 5.917 6.037 5.917 11.831 0 3.958-.77 6.252-4.02 10.566.163-1.188.08-2.442.163-3.742a.424.424 0 00-.245-.412c-2.621-1.22-4.237-3.417-5.325-6.641a27.845 27.845 0 01-.597-2.052l-.181-.738-.519-2.181-.024-.07a.43.43 0 00-.694-.128c-7.266 7.193-15.31 11.913-26.146 11.638h-.655c-.355.119-.306.214.106.35l3.935.53v1.853c.584 8.784 3.166 14.562 7.737 17.272l.055.143c.47 1.287.704 2.713.704 4.281l-.003 1.22c-.005.526-.017.994-.04 1.494-.057 1.27-.176 2.19-.344 2.541-.217.455-.821.815-1.847 1.05-8.57.855-13.821 4.604-15.66 11.222h-.528l-.211-.004c-6.631-.204-13.927-9.955-13.927-19.11 0-9.029 4.404-15.83 14.825-23.646l.14-.105.095-.64c1.801-11.77 6.38-18.414 14.643-21.281zm13.138 36.391c.198.128.255.391.126.588-.656 1.001-.887 1.282-1.822 1.596-.936.314-3.763.444-5.207.278a.426.426 0 01-.376-.47c.027-.233.24-.4.475-.374 3.277.376 5.33-.146 6.211-1.493a.43.43 0 01.593-.125z"
          opacity=".136"
        />
      </g>
    </g>
  </svg>
));
