import React, { FC, useRef } from 'react';
import {
  mobileProcessSuccessful,
  mobileProcessCancelled,
  goToMobileText,
  tryAgainButtonText,
  phoneBannerText,
} from './ScanLayout/constants';
import {
  Button,
  Container,
  FlowInfo,
  Content,
  FailureIcon,
  FinishedContent,
  HelpButton,
  HelpContent,
  HelpText,
  HelpTextContainer,
  HelpTitle,
  MobileScanIcon,
  OutputContent,
  SuccessIcon,
  Text,
} from './styles';
import { Props } from './types';
import useLogic from './logic';
import ScanLayout from './ScanLayout';

const Scan: FC<Props> = ({
  example,
  location: { search: query },
  webSocket,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const {
    isMobile,
    fromMobile,
    mobileFlowSuccess,
    onCancelMobileFlow,
    onHandleFinalValidation,
    onHandleBackToFlow,
    finalValidationInProgress,
    currentDocumentType,
    pictureTakingFinished,
  } = useLogic(query, webSocket, bottomRef);

  return (
    <Container>
      {mobileFlowSuccess !== undefined ? (
        <FlowInfo>
          {mobileFlowSuccess ? (
            <OutputContent>
              <SuccessIcon />
              <Text>{mobileProcessSuccessful}</Text>
            </OutputContent>
          ) : (
            <OutputContent>
              <FailureIcon />
              <Text>{mobileProcessCancelled}</Text>
              <Button variant="link" onClick={onHandleBackToFlow}>
                {tryAgainButtonText}
              </Button>
            </OutputContent>
          )}
        </FlowInfo>
      ) : (
        <Content>
          <ScanLayout
            documentType={currentDocumentType}
            example={example}
            webSocket={webSocket}
            pictureTakingFinished={pictureTakingFinished}
            onHandleFinalValidation={onHandleFinalValidation}
            finalValidationInProgress={finalValidationInProgress}
            fromMobile={fromMobile}
          />
          {fromMobile ? (
            <FinishedContent>
              <Button variant="link" onClick={onCancelMobileFlow}>
                Cancel
              </Button>
            </FinishedContent>
          ) : (
            <HelpContent>
              {!isMobile && (
                <>
                  <MobileScanIcon />
                  <HelpTextContainer>
                    <HelpTitle>
                      Scan the {currentDocumentType} with your phone.
                    </HelpTitle>
                    <HelpText>{phoneBannerText}</HelpText>
                  </HelpTextContainer>
                  <HelpButton variant="white" to="/mobile">
                    {goToMobileText}
                  </HelpButton>
                </>
              )}
            </HelpContent>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Scan;
