import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 34 34" {...props}>
    {children}
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M25.3083444,12.75 L20.6916556,12.75 C18.2294973,12.75 17.3261184,12.9798517 16.423162,13.462758 C15.5780188,13.9147455 14.9147455,14.5780188 14.462758,15.423162 C13.9798517,16.3261184 13.75,17.2294973 13.75,19.6916556 L13.75,24.3083444 C13.75,26.7705027 13.9798517,27.6738816 14.462758,28.576838 C14.9147455,29.4219812 15.5780188,30.0852545 16.423162,30.537242 C17.3261184,31.0201483 18.2294973,31.25 20.6916556,31.25 L25.3083444,31.25 C27.7705027,31.25 28.6738816,31.0201483 29.576838,30.537242 C30.4219812,30.0852545 31.0852545,29.4219812 31.537242,28.576838 C32.0201483,27.6738816 32.25,26.7705027 32.25,24.3083444 L32.25,19.6916556 C32.25,17.2294973 32.0201483,16.3261184 31.537242,15.423162 C31.0852545,14.5780188 30.4219812,13.9147455 29.576838,13.462758 C28.6738816,12.9798517 27.7705027,12.75 25.3083444,12.75 Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M23.2277655,8.44155321 C21.5133947,3.26213278 17.1330741,0 12,0 C5.372583,0 -1.84741111e-13,5.43794942 -1.84741111e-13,13.4193548 C-1.84741111e-13,21.4007603 5.372583,32 12,32"
      />
      <circle
        cx="23"
        cy="22"
        r="4.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle cx="23" cy="22" r="1" fill="#4A61F2" />
    </g>
  </svg>
));
