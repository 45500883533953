import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ContextOne } from 'context';
import getOriginFromQuery from 'utils/getOriginFromQuery';
import { EvaluationOutcome, Props } from './types';

function useLogic(query: string, webSocket: Props['webSocket']) {
  const history = useHistory();
  const { dispatch } = useContext(ContextOne);
  const { state } = useContext(ContextOne);
  const { sessionId, iframeInfo } = state;
  const fromMobile = !!getOriginFromQuery(query);
  const [finished, setFinished] = useState(false);
  // @ts-ignore
  const { resultOutcome, maxAttemptsReached } =
    history.location.state ?? undefined;

  const handleResult = useCallback(() => {
    if (
      resultOutcome === EvaluationOutcome.approved ||
      resultOutcome === EvaluationOutcome.manualReview ||
      maxAttemptsReached
    ) {
      setFinished(true);
      if (fromMobile) {
        return (
          webSocket &&
          webSocket.emit('finishMobileFlow', {
            room: sessionId,
            iframeInfo,
          })
        );
      }
      const {
        documentTokenFront,
        documentTokenBack,
        evaluationToken,
        entityToken,
        outcome,
      } = iframeInfo;
      return window.parent.postMessage(
        {
          document_token_front: documentTokenFront,
          document_token_back: documentTokenBack,
          evaluation_token: evaluationToken,
          entity_token: entityToken,
          outcome,
        },
        '*',
      );
    }
    if (resultOutcome === EvaluationOutcome.denied) {
      dispatch({ type: 'resetContext' });
    }
    return history.goBack();
  }, [
    fromMobile,
    dispatch,
    iframeInfo,
    sessionId,
    webSocket,
    history,
    resultOutcome,
    maxAttemptsReached,
  ]);

  function renderDescription(): string {
    if (maxAttemptsReached) {
      return '';
    }
    if (fromMobile && finished) {
      return 'Check back on your computer!';
    }
    switch (resultOutcome) {
      case EvaluationOutcome.retakeImages:
        return 'Please, go back and try again';
      case EvaluationOutcome.manualReview:
        return "Thank you! We're reviewing your images and will get back to you soon";
      default:
        return '';
    }
  }

  function renderTitle(): string {
    if (maxAttemptsReached) {
      return 'Sorry, we are unable to verify your document at this time';
    }
    switch (resultOutcome) {
      case EvaluationOutcome.denied:
        return 'Denied';
      case EvaluationOutcome.retakeImages:
        return 'Images need to be retaken';
      default:
        return 'Success';
    }
  }

  function renderButtonLabel(): string {
    if (
      resultOutcome === EvaluationOutcome.approved ||
      resultOutcome === EvaluationOutcome.manualReview ||
      maxAttemptsReached
    ) {
      return 'Finish';
    }
    return 'Retry';
  }

  return {
    finished,
    handleResult,
    resultOutcome,
    maxAttemptsReached,
    renderTitle,
    renderDescription,
    renderButtonLabel,
  };
}

export default useLogic;
