import React, { FC } from 'react';
import { Prompt } from 'react-router-dom';
import { EvaluationOutcome, Props } from './types';
import {
  Container,
  Content,
  Title,
  Description,
  ButtonContainer,
  Button,
  IconContainer,
  SuccessIcon,
  DeniedIcon,
  WarningIcon,
} from './styles';
import useLogic from './logic';

const Result: FC<Props> = ({
  location: { search: query },
  webSocket,
  ...rest
}) => {
  const {
    handleResult,
    resultOutcome,
    maxAttemptsReached,
    finished,
    renderTitle,
    renderDescription,
    renderButtonLabel,
  } = useLogic(query, webSocket);

  function renderOutcomeIcon() {
    switch (resultOutcome) {
      case EvaluationOutcome.denied:
        return <DeniedIcon />;
      case EvaluationOutcome.retakeImages:
        return <WarningIcon />;
      default:
        return <SuccessIcon />;
    }
  }

  return (
    <Container {...rest}>
      <Prompt when={maxAttemptsReached} message={() => false} />
      <Content>
        <IconContainer>{renderOutcomeIcon()}</IconContainer>
        <Title outcome={resultOutcome}>{renderTitle()}</Title>
        <Description>{renderDescription()}</Description>
        <ButtonContainer>
          {!finished && (
            <Button onClick={handleResult}>{renderButtonLabel()}</Button>
          )}
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default Result;
