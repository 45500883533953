import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { container, loadingAnimation } from 'styles/mixins';
import addAlpha from 'utils/addAlpha';
import { Spinner } from 'components/Vectors';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem 0;
`;

export const QrContent = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  margin: 2rem auto 3rem;
  padding: 2.5625rem;
  position: relative;
  border-radius: 1rem;
  box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LoadingContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 16.25rem;
  margin: 3rem auto 3.375rem;
  padding: 2.875rem;

  svg {
    margin-bottom: 1.8125rem;
  }
`;

export const Title = styled.span`
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 2rem;
  max-width: 13rem;
  text-align: center;
`;

export const Button = styled(DefaultButton)``;

export const Text = styled.p`
  font-size: 1.25rem;
`;

export const SpinnerIcon = styled(Spinner)`
  ${loadingAnimation};
  height: 3.6875rem;
  width: 3.6875rem;
`;
