import styled from 'styled-components';
import { container, title } from 'styles/mixins';
import DefaultDeniedIcon from 'components/Vectors/DeniedScreenIcon';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  ${container};
`;

export const Content = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const DeniedIcon = styled(DefaultDeniedIcon)`
  height: 9.25rem;
  margin-bottom: 2rem;
  width: 9.25rem;
`;

export const Title = styled.h2`
  ${title};
  color: ${({ theme }) => theme.colors.white};
  max-width: 20rem;
`;
