import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 20 20" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
    >
      <path d="M1 1l18 18M1 19L19 1" />
    </g>
  </svg>
));
