import { useHistory } from 'react-router';
import { useCallback, useContext } from 'react';
import { ContextOne } from 'context';

function useLogic(to?: string) {
  const history = useHistory();
  const { push, goBack, location } = useHistory();
  const { dispatch } = useContext(ContextOne);

  const handleChangeDocument = useCallback(() => {
    // Clear current document data if we go back to document selector
    if (location.pathname === '/step-2') {
      dispatch({ type: 'resetContext' });
    }
    return to ? push(to) : goBack();
  }, [dispatch, goBack, push, to, location]);

  return { history, handleChangeDocument };
}

export default useLogic;
