import React, { FC } from 'react';
import {
  faInfoCircle,
  faCheck,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Container,
  SuccessIcon,
  InfoIcon,
  OutcomeReason,
  OutcomeReasonContainer,
  RejectedPhoto,
  CheckingIcon,
  TagLabel,
} from './styles';
import { Props } from './types';

const Tag: FC<Props> = ({ type, outcomeReason, side, ...rest }) => {
  let content;

  switch (type) {
    case 'accepted':
      content = <SuccessIcon icon={faCheck} />;
      break;
    case 'rejected':
      content = (
        <RejectedPhoto>
          <OutcomeReasonContainer title={outcomeReason}>
            <InfoIcon icon={faInfoCircle} />
            <OutcomeReason>{outcomeReason}</OutcomeReason>
          </OutcomeReasonContainer>
          <Button variant="link" to={`/camera-${side}`}>
            Retake {side}
          </Button>
        </RejectedPhoto>
      );
      break;
    case 'checking':
    default:
      content = (
        <>
          <CheckingIcon icon={faSyncAlt} />
          <TagLabel>Checking</TagLabel>
        </>
      );
  }
  return (
    <Container type={type} {...rest}>
      {content}
    </Container>
  );
};

export default Tag;
