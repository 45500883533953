import React, { FC } from 'react';
import { faVideo, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { useDropzone } from 'react-dropzone';
import {
  AlloyWebCamAsBackground,
  AlloyWebCam,
  Button,
  CameraContent,
  TopInfo,
  CameraDisabledIcon,
  Container,
  LightBulbIcon,
  LoadingVideoContainer,
  Mask,
  NotificationContainer,
  NotificationText,
  TakePhotoButton,
  TakePhotoIcon,
  Text,
  Title,
  VideoCameraIcon,
  DropZone,
  DragAndDropLabel,
  UploadIcon,
  TimesIcon,
} from './styles';
import { Props } from './types';
import useLogic, { MEDIA_CONSTRAINTS, selfieConstraints } from './logic';
import { dragAndDrop } from './constants';

const Camera: FC<Props> = ({ cameraSide, ...rest }) => {
  const {
    type,
    cameraLoaded,
    inProgress,
    outcomeReason,
    rootRef,
    webCamRef,
    handleNewPicture,
    handleCameraUserMedia,
    handleCameraUserMediaError,
    noCamera,
    onDrop,
    onCancelCapture,
    documentType,
    cameraTitle,
    takePictureExplanationText,
  } = useLogic(cameraSide);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/*'],
  });

  return (
    <Container ref={rootRef} {...rest}>
      <AlloyWebCamAsBackground
        device={type ?? ''}
        audio={false}
        screenshotQuality={1}
        screenshotFormat="image/png"
        videoConstraints={
          cameraSide === 'selfie' ? selfieConstraints : MEDIA_CONSTRAINTS
        }
        imageSmoothing
        minScreenshotHeight={1080}
        minScreenshotWidth={1920}
        $selfie={cameraSide === 'selfie'}
        {...({ ref: webCamRef } as any)}
      />
      <AlloyWebCam
        device={type ?? ''}
        audio={false}
        screenshotFormat="image/png"
        videoConstraints={
          cameraSide === 'selfie' ? selfieConstraints : MEDIA_CONSTRAINTS
        }
        onUserMedia={handleCameraUserMedia}
        onUserMediaError={handleCameraUserMediaError}
        imageSmoothing
        minScreenshotHeight={1080}
        minScreenshotWidth={1920}
        $documentType={documentType}
        $cameraSide={cameraSide}
      />
      <CameraContent>
        <TopInfo $documentType={documentType} $cameraSide={cameraSide}>
          {cameraTitle && <Title>{cameraTitle}</Title>}
          <Text $documentType={documentType}>
            {takePictureExplanationText()}
          </Text>
        </TopInfo>
        {(noCamera || inProgress) && (
          <LoadingVideoContainer $cameraSide={cameraSide}>
            {noCamera ? (
              <DropZone {...getRootProps()}>
                <input {...getInputProps()} />
                <DragAndDropLabel>
                  <UploadIcon icon={faUpload} />
                  {dragAndDrop}
                </DragAndDropLabel>
              </DropZone>
            ) : (
              <Mask
                $documentType={documentType}
                inProgress={inProgress}
                $cameraSide={cameraSide}
              >
                <CameraDisabledIcon />
              </Mask>
            )}
          </LoadingVideoContainer>
        )}

        {outcomeReason && (
          <NotificationContainer $cameraSide={cameraSide}>
            {outcomeReason !== 'Approved' && (
              <>
                {inProgress ? (
                  <VideoCameraIcon icon={faVideo} />
                ) : (
                  <LightBulbIcon icon={faLightbulb} />
                )}
                <NotificationText>{outcomeReason}</NotificationText>
              </>
            )}
          </NotificationContainer>
        )}
        {cameraLoaded && (
          <TakePhotoButton
            onClick={() => handleNewPicture()}
            $cameraSide={cameraSide}
          >
            <TakePhotoIcon />
          </TakePhotoButton>
        )}
        <Button variant="link" onClick={onCancelCapture}>
          <TimesIcon />
        </Button>
      </CameraContent>
    </Container>
  );
};

export default Camera;
