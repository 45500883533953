import React, { FC } from 'react';
import {
  BackCardIcon,
  Button,
  Card,
  Container,
  FrontCardIcon,
  Tag,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const PictureId: FC<Props> = ({
  side = 'front',
  example,
  picture,
  firstRender,
  className,
  documentType = 'license',
}) => {
  const { pictureFront, tagType } = useLogic(picture, side);
  let buttonToShow;

  if (firstRender && !example) {
    if (side === 'back') {
      buttonToShow = !pictureFront.valid ? (
        <Button disabled>{`Take ${side}`}</Button>
      ) : (
        <Button to={`/camera-${side}`}>{`Take ${side}`}</Button>
      );
    } else {
      buttonToShow = (
        <Button to={`/camera-${side}`}>
          {documentType === 'passport' ? `Take picture` : `Take ${side}`}
        </Button>
      );
    }
  }

  let cardToShow;
  if (example || (picture && !picture.url)) {
    cardToShow = side === 'front' ? <FrontCardIcon /> : <BackCardIcon />;
  } else {
    cardToShow = (
      <Card
        firstRender={firstRender}
        valid={picture?.valid}
        src={picture?.url}
        $documentType={documentType}
      />
    );
  }

  return (
    <Container className={className}>
      {cardToShow}
      {buttonToShow}
      {!firstRender && !example && (
        <Tag
          type={tagType}
          side={side}
          outcomeReason={picture?.outcomeReason}
        />
      )}
    </Container>
  );
};

export default PictureId;
