import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 95 77" {...props}>
    {children}
    <g fill="#FFF" fillRule="nonzero">
      <path d="M3.287.469l.127.117 11.137 11.136A13.985 13.985 0 0119 11h39c7.732 0 14 6.268 14 14v.484l19.722-8.326a2 2 0 012.773 1.696L94.5 19v39a2 2 0 01-2.641 1.895l-.137-.053L72 51.515V52c0 4.675-2.292 8.815-5.813 11.358l10.227 10.228a2 2 0 01-2.701 2.945l-.127-.117-73-73A2 2 0 013.287.47zM6.291 19.12l3.1 3.101a9.99 9.99 0 00-.387 2.5L9 25v27c0 5.43 4.327 9.848 9.72 9.996L19 62h30.171l4 4H19c-7.732 0-14-6.268-14-14V25c0-2.1.462-4.092 1.29-5.88zM58 15H19c-.375 0-.746.02-1.11.061l45.416 45.417a9.994 9.994 0 004.69-8.198L68 52V25c0-5.43-4.327-9.848-9.72-9.996L58 15zm32.5 7.015L72 29.826v17.347l18.5 7.811V22.015z" />
    </g>
  </svg>
));
