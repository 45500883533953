import { useCallback, useContext, useEffect, useState } from 'react';
import { ContextOne } from 'context';
import getOriginFromQuery from 'utils/getOriginFromQuery';
import uuid from 'uuid';
import openSocket from 'socket.io-client';
import { IframeInfo, Init } from 'model';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import api from 'API';

let socket: SocketIOClient.Socket;
if (process.env.REACT_APP_API_URL) {
  socket = openSocket(process.env.REACT_APP_API_URL);
}

function useLogic(query: string) {
  const { state, dispatch } = useContext(ContextOne);
  const { sessionId, mobileFlowInProgress } = state;
  const [mainSessionId, setMainSessionId] = useState(false);
  const [tokenId, setTokenId] = useState<string>('');
  const [externalId, setExternalId] = useState<string>('');
  const [clientKey, setClientKey] = useState<string>('');
  const [evaluationInfo, setEvaluationInfo] = useState(false);
  const [connectedToRoom, setConnectedToRoom] = useState(false);
  const [queryParams, setQueryParams] = useState(false);
  const fromMobile = getOriginFromQuery(query);
  const { push } = useHistory();
  const { search } = useLocation();

  const retrieveQueryParams = useCallback(async () => {
    if (!queryParams) {
      const initParams: Init = queryString.parse(search);
      const {
        key,
        entityToken,
        externalEntityId,
        production,
        maxEvaluationAttempts,
        documents,
        nameFirst,
        nameLast,
        addressLine1,
        addressLine2,
        addressCity,
        addressState,
        addressPostalCode,
        addressCountryCode,
        birthDate,
        selfie,
        currentDocumentType,
      } = initParams;

      // Set selfie as needed for finishing the flow if specified in init params
      if (selfie) {
        dispatch({
          type: 'setRequiredSelfie',
          payload: selfie,
        });
      }

      // Set production mode if specified in init params
      if (production) {
        dispatch({
          type: 'toggleProductionMode',
          payload: true,
        });
      }

      // Set entity token if specified in init params
      if (!tokenId) {
        if (entityToken) {
          setTokenId(entityToken);
          dispatch({ type: 'setEntityToken', payload: entityToken });
        }
      }

      // Set external entity id if specified in init params
      if (!externalId) {
        if (externalEntityId) {
          setExternalId(externalEntityId);
          dispatch({ type: 'setExternalEntityId', payload: externalEntityId });
        }
      }

      // Set max evaluations for final evaluation if specified in init params
      if (maxEvaluationAttempts) {
        dispatch({
          type: 'setMaxEvaluationAttempts',
          payload: parseInt(maxEvaluationAttempts, 10),
        });
      }

      // Set multiple document types if specified in init params
      if (documents) {
        // If its an array
        if (
          Array.isArray(documents) &&
          documents.every(it => typeof it === 'string')
        ) {
          dispatch({
            type: 'setAllowedDocumentTypes',
            payload: documents,
          });
        } else {
          dispatch({
            type: 'setCurrentDocumentType',
            payload: documents ?? 'license',
          });
        }
      } else {
        dispatch({
          type: 'setCurrentDocumentType',
          payload: currentDocumentType ?? 'license',
        });
      }

      if (!clientKey) {
        if (key) {
          setClientKey(key);
          const response = await api.init(key);
          dispatch({ type: 'setClientKey', payload: key });
          dispatch({ type: 'setAccessToken', payload: response.access_token });
          const publicClientInfo = await api.getPublicClientInfo(
            key,
            response.access_token,
          );
          const clientInfo = { set: true, ...publicClientInfo };
          dispatch({ type: 'setClientInfo', payload: clientInfo });
        }
      }

      if (!evaluationInfo) {
        dispatch({
          type: 'setEvaluationData',
          payload: {
            nameFirst,
            nameLast,
            addressLine1,
            addressLine2,
            addressCity,
            addressState,
            addressPostalCode,
            addressCountryCode,
            birthDate,
          },
        });
        setEvaluationInfo(true);
      }
      setQueryParams(true);
    }
    dispatch({ type: 'setDataLoaded', payload: true });
  }, [
    evaluationInfo,
    dispatch,
    queryParams,
    search,
    clientKey,
    tokenId,
    externalId,
  ]);

  useEffect(() => {
    retrieveQueryParams().then();
  }, [retrieveQueryParams]);

  const setUniqueSessionId = useCallback(() => {
    if (!sessionId) {
      dispatch({ type: 'setSessionId', payload: uuid() });
    }
  }, [dispatch, sessionId]);

  const initSessionId = useCallback(() => {
    if (!fromMobile && !mainSessionId && !sessionId) {
      setUniqueSessionId();
      setMainSessionId(true);
    } else if (!mainSessionId && !sessionId) {
      dispatch({ type: 'setSessionId', payload: fromMobile });
      setMainSessionId(true);
    }
  }, [dispatch, fromMobile, mainSessionId, setUniqueSessionId, sessionId]);

  useEffect(() => {
    initSessionId();
  }, [initSessionId]);

  const handleWebsocketRoomConnection = useCallback(() => {
    if (!connectedToRoom && fromMobile) {
      socket.emit('joinRoom', fromMobile);
      setConnectedToRoom(true);
    } else if (!connectedToRoom && mainSessionId) {
      socket.emit('joinRoom', sessionId);
      setConnectedToRoom(true);
    }
  }, [connectedToRoom, fromMobile, mainSessionId, sessionId]);

  useEffect(() => {
    handleWebsocketRoomConnection();
  }, [handleWebsocketRoomConnection]);

  const handleMobileFlow = useCallback(
    data => {
      if (mobileFlowInProgress !== data) {
        dispatch({ type: 'setMobileFlowInProgress', payload: data });
      }
    },
    [dispatch, mobileFlowInProgress],
  );

  const handleFinishMobileFlow = useCallback(
    (data: IframeInfo) => {
      const {
        documentTokenFront,
        documentTokenBack,
        evaluationToken,
        entityToken,
        outcome,
      } = data;
      dispatch({
        type: 'setIframeInfo',
        payload: {
          documentTokenFront,
          documentTokenBack,
          evaluationToken,
          entityToken,
          outcome,
        },
      });
      return push(`/result${query}`, { resultOutcome: outcome.toLowerCase() });
    },
    [push, query, dispatch],
  );

  const handleCancelMobileFlow = useCallback(() => {
    dispatch({ type: 'setMobileFlowInProgress', payload: false });
  }, [dispatch]);

  useEffect(() => {
    socket.on('mobileFlowStatus', (data: boolean) => {
      handleMobileFlow(data);
    });
  }, [handleMobileFlow]);

  useEffect(() => {
    socket.on('mobileFlowFinished', (data: IframeInfo) => {
      handleFinishMobileFlow(data);
    });
  }, [handleFinishMobileFlow]);

  useEffect(() => {
    socket.on('mobileFlowCanceled', () => {
      handleCancelMobileFlow();
    });
  }, [handleCancelMobileFlow]);

  useEffect(() => {
    socket.on('error', (err: { type: string; description: number }) => {
      // eslint-disable-next-line no-console
      console.log(`Received socket error => ${err}`);
    });
  }, []);

  return { socket };
}

export default useLogic;
