export const licenseScanExplanation: string =
  'Take a photo of both sides. It may take time to validate your personal information.';

export const passportScanExplanation: string =
  'Open your passport and take a picture from inside. It may take a while to validate your personal information.';

export const exampleScanExplanation: string =
  "Here's an example of what the result should look like.";

export const mobileProcessSuccessful: string =
  'Process successfully completed, go back to your computer!';

export const mobileProcessCancelled: string =
  'Process cancelled, you can go back to your computer.';

export const goToMobileText: string = 'Go to mobile';

export const exampleButtonText: string = 'Show me an example';

export const tryAgainButtonText: string = 'I want to try again.';

export const phoneBannerText: string =
  'Use your phone to validate your document.';

export const pictureTakingFinishedLabel: string =
  'Process is almost finished, proceed to perform the final verification of your document.';
