import { useCallback, useContext, useEffect, useState } from 'react';
import { ContextOne } from 'context';
import queryString from 'query-string';

function useLogic(webSocket: SocketIOClient.Socket | undefined) {
  const { state } = useContext(ContextOne);
  const {
    access_token,
    sessionId,
    entityToken,
    clientKey,
    externalEntityId,
    mobileFlowInProgress,
    evaluationData,
    productionMode,
    maxEvaluationAttempts,
    currentDocumentType,
    requiredSelfie,
  } = state;
  const [mobileFlowEnabled, setMobileFlowEnabled] = useState(false);
  let url;
  if (process.env.REACT_APP_URL) {
    url = `${
      process.env.REACT_APP_URL
    }/step-2?from=${sessionId}&key=${clientKey}${
      entityToken ? `&entityToken=${entityToken}` : ''
    }${
      externalEntityId ? `&externalEntityId=${externalEntityId}` : ''
    }&${queryString.stringify(evaluationData)}&access_token=${access_token}${
      productionMode ? '&production=true' : ''
    }&maxEvaluationAttempts=${maxEvaluationAttempts}&currentDocumentType=${currentDocumentType}${
      requiredSelfie ? '&selfie=true' : ''
    }`;
  }

  const handleLoad = useCallback(() => {
    if (mobileFlowEnabled !== mobileFlowInProgress) {
      setMobileFlowEnabled(mobileFlowInProgress);
    }
  }, [mobileFlowEnabled, mobileFlowInProgress]);

  const handleMobileFlow = useCallback(() => {
    return webSocket && webSocket.emit('cancelMobileFlow', sessionId);
  }, [sessionId, webSocket]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const handleCancelMobileFlow = useCallback(() => {
    handleMobileFlow();
  }, [handleMobileFlow]);

  return {
    url,
    sessionId,
    mobileFlowEnabled,
    handleLoad,
    handleMobileFlow,
    handleCancelMobileFlow,
  };
}

export default useLogic;
