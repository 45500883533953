export default {
  weights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  colors: {
    primary: '#4A61F2',
    primaryAlt: '#2642EF',
    secondary: '#B067AB',
    secondaryAlt: '#964E91',
    alert: '#D73D09',
    warning: '#FAB72D',
    success: '#69CC8B',
    error: '#EE6767',
    white: '#ffffff',
    logo: '#82C7B5',
    logoDark: '#006666',
    shadow: '#3F535C',
    dark: '#081D2D',
    overlay: '#141C26',
    permissionsBg: '#04101A',
    black: '#000000',
    gray100: '#F8F9FD',
    gray200: '#DADEE3',
    gray300: '#BCC2C9',
    gray400: '#9EA7AF',
    gray500: '#808B95',
    gray600: '#62707B',
    gray700: '#445461',
    gray800: '#263947',
    inputBorder: '#BCC2C9',
    inputFocusBg: '#F3F4FE',
    inputErrorBg: '#FCF0ED',
  },
  ZIndex: {
    main: 0,
    navBar: 1,
  },
};
