import React, { FC } from 'react';
import PictureId from 'containers/Main/PictureId';
import BackButton from 'components/BackButton';
import {
  faArrowRight,
  faSyncAlt,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Props } from './types';
import {
  Container,
  Content,
  Info,
  CardContainer,
  ExampleButton,
  Text,
  Title,
  ArrowIcon,
  CheckingIcon,
  FinishButton,
  FinishedContent,
  SelfieText,
  CheckIcon,
  Selfie,
  SelfieContent,
  RetakeSelfieButton,
  EyeIcon,
  TakeSelfieButton,
} from './styles';
import { exampleButtonText } from './constants';
import useLogic from './logic';

const ScanLayout: FC<Props> = ({
  example,
  webSocket,
  documentType = 'license',
  pictureTakingFinished,
  onHandleFinalValidation,
  finalValidationInProgress,
  fromMobile,
  ...rest
}) => {
  const {
    buildLayoutTitle,
    buildExplanation,
    requiredSelfie,
    selfieToken,
    onHandleRequiredSelfie,
    currentDocumentType,
    selfieInProgress,
    pictureFront,
    pictureBack,
    selfieImage,
    onToggleSelfiePreview,
    showSelfie,
  } = useLogic(pictureTakingFinished, documentType, example);
  let actions;

  if (pictureTakingFinished) {
    if (selfieInProgress || (requiredSelfie && !selfieToken)) {
      actions = (
        <FinishedContent>
          <FinishButton
            disabled={selfieInProgress}
            onClick={onHandleRequiredSelfie}
          >
            Selfie verification
          </FinishButton>
          <SelfieText>
            {selfieInProgress
              ? 'Validating selfie...'
              : `This will help us match your face with the ${currentDocumentType} photo as a final security check.`}
          </SelfieText>
        </FinishedContent>
      );
    } else {
      actions = (
        <FinishedContent>
          {!selfieInProgress && requiredSelfie && selfieToken && (
            <>
              <SelfieText
                $finished={!selfieInProgress && requiredSelfie && !!selfieToken}
              >
                Selfie taken! <CheckIcon />
                <TakeSelfieButton
                  variant="link"
                  onClick={onToggleSelfiePreview}
                  title={showSelfie ? 'Hide selfie' : 'Show selfie'}
                >
                  <EyeIcon icon={showSelfie ? faEyeSlash : faEye} />
                </TakeSelfieButton>
              </SelfieText>
            </>
          )}
          <FinishButton onClick={onHandleFinalValidation}>
            {finalValidationInProgress ? (
              <CheckingIcon icon={faSyncAlt} />
            ) : (
              'Send pictures'
            )}
          </FinishButton>
        </FinishedContent>
      );
    }
  }
  return (
    <Container {...rest}>
      <Content>
        {!fromMobile && <BackButton to={example ? '' : '/step-1'} />}
        <Info>
          <Title>{buildLayoutTitle()}</Title>
          <Text example={example}>{buildExplanation()}</Text>
          {!example && !pictureTakingFinished && (
            <ExampleButton variant="link" to="/example">
              {exampleButtonText}
              <ArrowIcon icon={faArrowRight} />
            </ExampleButton>
          )}
          {!example && actions}
        </Info>
      </Content>
      {showSelfie ? (
        <SelfieContent>
          <Selfie alt="selfie" src={`data:image/jpeg;base64,${selfieImage}`} />
          <RetakeSelfieButton to="/selfie">Retake</RetakeSelfieButton>
        </SelfieContent>
      ) : (
        <CardContainer $documentType={documentType}>
          <PictureId
            firstRender={pictureFront.valid === undefined}
            picture={pictureFront}
            example={example}
            documentType={documentType}
          />
          {documentType === 'license' && (
            <PictureId
              firstRender={pictureBack.valid === undefined}
              picture={pictureBack}
              example={example}
              documentType={documentType}
              side="back"
            />
          )}
        </CardContainer>
      )}
    </Container>
  );
};

export default ScanLayout;
