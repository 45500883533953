import React, { FC } from 'react';
import { Container, Title } from './styles';

const NotFound: FC = () => {
  return (
    <Container>
      <Title>Sorry, Something went wrong.</Title>
    </Container>
  );
};
export default NotFound;
