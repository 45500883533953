import React, { FC } from 'react';
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import BackButton from 'components/BackButton';
import {
  Button,
  CheckIcon,
  ConfirmedButton,
  Container,
  Content,
  Info,
  ErrorText,
  ExplanationText,
  Form,
  LoadingIcon,
  PhoneSection,
  QrSection,
  RetryButton,
  RightSection,
  Section,
  Subtitle,
  Text,
  Title,
  LoadingContent,
  SpinnerIcon,
  PhoneInputContainer,
} from './styles';
import useLogic from './logic';

const Mobile: FC = ({ ...rest }) => {
  const {
    phoneNumber,
    loading,
    emailSent,
    errorMessage,
    handlePhoneNumberChange,
    handlePhoneNumberFormReset,
    handlePhoneNumberFormSubmit,
    mobileFlowEnabled,
  } = useLogic();

  let sentEmailText;
  if (emailSent) {
    sentEmailText = (
      <ExplanationText>
        Didn&apos;t receive the message yet? Want to try with another number?
        <RetryButton variant="link" onClick={handlePhoneNumberFormReset}>
          Try again
        </RetryButton>
      </ExplanationText>
    );
  } else if (emailSent === false) {
    sentEmailText = <ErrorText>{errorMessage}</ErrorText>;
  }

  return (
    <Container {...rest}>
      {mobileFlowEnabled ? (
        <LoadingContent>
          <SpinnerIcon />
          <Text>Mobile flow now in progress...</Text>
        </LoadingContent>
      ) : (
        <Content>
          <BackButton to="/step-2" />
          <Info>
            <PhoneSection>
              <Section>
                <Title>
                  If you want to use your phone
                  <br /> get a link through SMS
                </Title>
                <Text>
                  Enter your phone number in the field besides and we will send
                  you a text with all the instructions.
                </Text>
              </Section>
              <RightSection>
                <Form onSubmit={handlePhoneNumberFormSubmit}>
                  <PhoneInputContainer
                    emailSent={emailSent}
                    country="us"
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    disabled={emailSent}
                  />
                  {emailSent ? (
                    <ConfirmedButton>
                      <CheckIcon icon={faCheck} /> Link sent
                    </ConfirmedButton>
                  ) : (
                    <Button
                      type="submit"
                      disabled={
                        loading || !phoneNumber || phoneNumber.length < 7
                      }
                    >
                      {loading && <LoadingIcon icon={faSyncAlt} />}
                      {loading ? 'Sending SMS' : 'Get link'}
                    </Button>
                  )}
                </Form>
                {sentEmailText}
              </RightSection>
            </PhoneSection>
            <QrSection>
              <Section>
                <Subtitle>Do you prefer to scan the QR code?</Subtitle>
                <Text>
                  The ID verification process will start right away, we will
                  need access to your phone camera.
                </Text>
              </Section>
              <RightSection>
                <Button type="button" variant="ghost" to="/QrCode">
                  Show qr code
                </Button>
              </RightSection>
            </QrSection>
          </Info>
        </Content>
      )}
    </Container>
  );
};

export default Mobile;
