import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';
import Background from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 20 20" {...props}>
    {children}
    <g fill="none" fillRule="evenodd">
      <Background width="20" height="20" fill="currentColor" rx="10" />
      <path
        fill="#FFF"
        d="M14.986 5.705l.85.85a.601.601 0 010 .85l-6.913 6.913a.601.601 0 01-.85 0L4.163 10.41a.601.601 0 010-.85l.85-.85a.601.601 0 01.85 0l2.633 2.632 5.639-5.637a.601.601 0 01.85 0z"
      />
    </g>
  </svg>
));
