import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 24 20" {...props}>
    {children}
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="M14.93 1H9.07a1 1 0 00-.832.445L6.535 4H3c-.552 0-1.052.224-1.414.586A1.994 1.994 0 001 6v11c0 .552.224 1.052.586 1.414.362.362.862.586 1.414.586h18c.552 0 1.052-.224 1.414-.586.362-.362.586-.862.586-1.414V6c0-.552-.224-1.052-.586-1.414A1.994 1.994 0 0021 4h-3.535l-1.703-2.555A1 1 0 0014.93 1z" />
      <circle cx="12" cy="11" r="4" />
    </g>
  </svg>
));
