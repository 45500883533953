import styled from 'styled-components';
import { container, title } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
`;

export const Title = styled.h2`
  ${title};
  color: ${({ theme }) => theme.colors.dark};
  margin: auto;
  max-width: 20rem;
`;
