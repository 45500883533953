import { ChangeEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ContextOne } from 'context';

function useLogic() {
  const { dispatch } = useContext(ContextOne);
  const history = useHistory();
  const [documentType, setDocumentType] = useState<string | undefined>(
    undefined,
  );

  const handleCheckboxGroup = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDocumentType = e?.target?.value;
    setDocumentType(selectedDocumentType);
    dispatch({ type: 'setCurrentDocumentType', payload: selectedDocumentType });
  };

  const handleNavigation = () => {
    if (!documentType) {
      return;
    }
    history.push('/step-2');
  };

  return {
    documentType,
    handleCheckboxGroup,
    handleNavigation,
  };
}

export default useLogic;
