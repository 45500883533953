import styled, { css } from 'styled-components';
import { from, loadingIcon } from 'styles/mixins';
import DefaultButton from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import addAlpha from 'utils/addAlpha';
import { DocumentType } from 'model';
import { CheckIcon as DefaultCheckIcon } from 'components/Vectors';

const defaultInfo = css`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  text-align: center;
  ${from.tablet`
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  `};
`;

export const Container = styled.div`
  ${defaultInfo};
  flex-grow: 1;
  flex-shrink: 0;
  padding: 2rem 1.875rem 4rem;
  position: relative;
  ${from.tablet`
     padding: 2rem 5rem 2rem 1.25rem
  `};
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${from.tablet`
    width: auto;
  `}
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem 0;
  ${from.tablet`
      align-items: flex-start;
      padding: 0 0 0 3rem;
  `}
`;

export const CardContainer = styled.div<{
  $documentType?: DocumentType;
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;

  > div:not(:first-child) {
    margin-top: 0.75rem;
  }

  ${({ $documentType }) =>
    $documentType === 'passport'
      ? css`
          ${from.tablet`
          margin-top: 4rem;
        `};
        `
      : css`
          ${from.tablet`
          margin-top: 0;
        `};
        `};
`;

export const ExampleButton = styled(DefaultButton)`
  align-items: center;
  display: flex;
  margin: 1rem 0;
`;

export const Text = styled.p<{ example?: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
  max-width: 20rem;

  ${from.tablet`
    text-align: left;
  `};

  ${({ example }) =>
    example &&
    css`
      ${from.tablet`
        margin-bottom: 3.5rem;
      `};
      margin-bottom: 1rem;
    `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;
  text-align: left;
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 0.75rem;
  margin-left: 0.6875rem;
  width: 0.75rem;
`;

export const CheckingIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
  ${loadingIcon};
`;

export const FinishButton = styled(DefaultButton)`
  flex-shrink: 0;
  max-width: 15rem;
`;

export const SelfieText = styled.span<{ $finished?: boolean }>`
  align-items: center;
  font-size: 0.875rem;
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.5)};
  display: flex;
  line-height: 1.5;
  margin: 0.75rem 0.25rem;
  max-width: 20rem;
  text-align: left;
  svg {
    margin-left: 0.5rem;
  }
  ${({ $finished }) =>
    $finished &&
    css`
      margin: 0 0.25rem 0.75rem;
    `}
`;

export const FinishedContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  ${from.tablet`
    align-items: flex-start;
  `};
`;

export const CheckIcon = styled(DefaultCheckIcon)`
  color: ${({ theme }) => theme.colors.success};
  height: 1rem;
  width: 1em;
`;

export const SelfieContent = styled.div`
  margin-top: 2rem;
  position: relative;
`;

export const TakeSelfieButton = styled(DefaultButton)``;

export const RetakeSelfieButton = styled(DefaultButton)`
  bottom: 0.75rem;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  right: 0.5rem;
  text-transform: capitalize;
`;

export const Selfie = styled.img`
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
  border-radius: 0.25rem;
  box-shadow: 0 0.625rem 1.25rem -0.375rem rgba(0, 0, 0, 0.15);
  height: 17rem;
  object-fit: cover;
  max-width: 15rem;
`;

export const EyeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 1rem;
  margin-left: 0.6875rem;
  width: 0.75rem;
`;
