import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 148 148" {...props}>
    {children}
    <defs>
      <filter
        id="a"
        width="185.3%"
        height="185.3%"
        x="-42.7%"
        y="-29.3%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadOuter1" />
        <feOffset dy="10" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="10"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.410741629 0 0 0 0 0.801715353 0 0 0 0 0.544305806 0 0 0 0.433948864 0"
        />
      </filter>
      <circle id="b" cx="37.5" cy="37.5" r="37.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="148" height="148" fill="#69CC8B" opacity=".075" rx="74" />
      <rect
        width="110"
        height="110"
        x="19"
        y="19"
        fill="#69CC8B"
        opacity=".075"
        rx="55"
      />
      <g transform="translate(37 37)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#69CC8B" xlinkHref="#b" />
      </g>
      <path
        fill="#FFF"
        d="M88.39 61.972l2.429 2.48c.67.684.67 1.794 0 2.479L71.064 87.093a1.693 1.693 0 01-2.429 0L57.47 75.697a1.779 1.779 0 010-2.48l2.429-2.479a1.693 1.693 0 012.43 0l7.52 7.677L85.96 61.972a1.693 1.693 0 012.43 0z"
      />
    </g>
  </svg>
));
