import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Main from './containers/Main';
import { ContextOneProvider } from './context';
import themes from './styles/themes';

ReactDOM.render(
  <ThemeProvider theme={themes.light}>
    <BrowserRouter>
      <ContextOneProvider>
        <Main />
      </ContextOneProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);
