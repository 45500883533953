import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/Button';
import 'react-phone-input-2/lib/bootstrap.css';
import {
  container,
  from,
  loadingAnimation,
  loadingIcon,
  text,
  title,
} from 'styles/mixins';
import addAlpha from 'utils/addAlpha';
import { Spinner } from 'components/Vectors';
import PhoneInput from 'react-phone-input-2';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
  padding: 2rem 1.875rem 4rem;
  ${from.tablet`
     padding: 2rem 3rem 2.25rem 1.25rem
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  ${from.tablet`
    padding-left: 3rem;
  `};
`;

export const Section = styled.div`
  max-width: 22rem;
`;

export const RightSection = styled.div`
  width: 14.0625rem;
  margin-top: 2rem;

  ${from.tablet`
    margin-left: auto;
    margin-top: 0;
  `};
`;

const titleDefault = css`
  ${title};
  text-align: center;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  ${titleDefault};
  line-height: 1.75rem;

  ${from.tablet`
    text-align: left;
  `};
`;

export const Subtitle = styled.h1`
  ${titleDefault};
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;

  ${from.tablet`
    text-align: left;
  `};
`;

export const Text = styled.p`
  ${text};
  text-align: center;

  ${from.tablet`
    text-align: left;
  `};
`;

export const Form = styled.form`
  position: relative;

  button {
    margin-top: 1rem;
  }
`;

const defaultText = css`
  font-size: 0.75rem;
  margin: 0.5rem 0 0;
  max-width: 14rem;
  position: absolute;
`;

export const ErrorText = styled.p`
  ${defaultText};
  color: ${({ theme }) => theme.colors.alert};
`;

export const ExplanationText = styled.p`
  ${defaultText};
  color: ${({ theme }) => addAlpha(theme.colors.dark, 0.5)};

  button {
    margin-left: 0.25rem;
  }
`;

const sectionDefault = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const PhoneSection = styled.div`
  ${sectionDefault};
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.black, 0.1)};
  padding-bottom: 4.5rem;
`;

export const QrSection = styled.div`
  ${sectionDefault};
  padding-top: 4.5rem;
`;

export const Button = styled(DefaultButton)`
  width: 100%;
`;

export const ConfirmedButton = styled.div`
  background-color: ${({ theme }) => theme.colors.success};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.021875rem;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  justify-content: center;

  svg {
    margin-right: 1rem;
  }
`;

export const RetryButton = styled(DefaultButton)`
  font-size: 0.75rem;
  font-weight: normal;
  justify-content: flex-start;
  padding: 0;
  text-transform: capitalize;
`;

const commonInputStyles = {
  width: '100%',
  borderRadius: '0.25rem',
  fontSize: '1rem',
};

export const PhoneInputStyle = {
  ...commonInputStyles,
  padding: '0.875rem 0.875rem 0.875rem 3.625rem',
  border: '0.0625rem solid #BCC2C9',
};

export const PhoneDisabledInputStyle = {
  ...commonInputStyles,
  border: '0.0625rem solid rgba(188,194,201,0.2)',
  padding: '0.875rem',
  textAlign: 'center',
};

export const PhoneInputContainer = styled(PhoneInput).attrs(
  ({ emailSent }) => ({
    dropdownStyle: { width: '16rem' },
    buttonStyle: emailSent ? { display: 'none' } : { display: 'block' },
    inputStyle: emailSent ? PhoneDisabledInputStyle : PhoneInputStyle,
  }),
)``;

export const LoadingIcon = styled(FontAwesomeIcon)<{ checking?: string }>`
  ${loadingIcon};
  color: ${({ theme }) => theme.colors.white};
  margin-right: 1rem;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const LoadingContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 16.25rem;
  margin: 3rem auto 3.375rem;
  padding: 2.875rem;

  svg {
    margin-bottom: 1.8125rem;
  }
`;

export const SpinnerIcon = styled(Spinner)`
  ${loadingAnimation};
  height: 3.6875rem;
  width: 3.6875rem;
`;
