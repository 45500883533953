import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Webcam from 'react-webcam';
import { container, from, text, title } from 'styles/mixins';
import DefaultButton from 'components/Button';
import addAlpha from 'utils/addAlpha';
import {
  TimesIcon as DefaultTimesIcon,
  CameraDisabled,
  TakePhotoIcon as DefaultTakePhotoIcon,
} from 'components/Vectors';

import { DocumentType } from 'model/Document';
import { CameraSide } from './types';

const absoluteCenter = css`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
`;

export const Container = styled.div<{ inProgress?: boolean }>`
  background-color: ${({ theme }) => theme.colors.dark};
  overflow: hidden;
  ${container};
`;

const landscapeRatio = css`
  height: 13.125rem;
  max-width: 21.25rem;
  width: 90%;
  ${from.tablet`
    width: 21.25rem;
  `};
`;

const portraitRatio = css`
  height: 18rem;
  ${from.tablet`
    max-width: 15rem;
  `};
`;

export const Mask = styled.div<{
  inProgress: boolean;
  $documentType: DocumentType;
  $cameraSide: CameraSide;
}>`
  ${absoluteCenter};
  border-radius: 1rem;
  box-shadow: 0.0625rem 0.0625rem 0 62.4375rem
    ${({ theme }) => addAlpha(theme.colors.overlay, 0.75)};

  ${({ $documentType }) =>
    ($documentType === 'license' || $documentType === 'passport') &&
    css`
      ${landscapeRatio};
    `}
  ${({ inProgress }) =>
    inProgress &&
    css`
      background-color: ${({ theme }) => theme.colors.permissionsBg};
      box-shadow: none;
    `};
  ${({ $cameraSide }) =>
    $cameraSide === 'selfie' &&
    css`
      ${portraitRatio};
    `}
`;

export const CameraContent = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  text-align: center;
`;

export const TopInfo = styled.div<{
  $documentType: DocumentType;
  $cameraSide: CameraSide;
}>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 10%;
  ${from.tablet`
    padding-top: 6%;
  `};
  ${({ $documentType, $cameraSide }) =>
    $documentType === 'passport' &&
    $cameraSide !== 'selfie' &&
    css`
      padding-top: 15%;
    `}
`;

export const Title = styled.div`
  ${title};
  margin-bottom: 0.5rem;
  ${from.tablet`margin-bottom: 1rem;`};
`;

export const Text = styled.p<{ $documentType: DocumentType }>`
  ${text};
  display: -webkit-box;
  margin: 0 auto;
  overflow: hidden;
  max-width: 85%;
  ${from.tablet`
    max-width: 70%;
  `};
`;

const commonPositions = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const NotificationContainer = styled.div<{ $cameraSide: CameraSide }>`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 80%;
  top: 72%;
  width: 100%;
  ${commonPositions};
  svg {
    margin-right: 0.75rem;
  }
  ${({ $cameraSide }) =>
    $cameraSide &&
    $cameraSide === 'selfie' &&
    css`
      top: 80%;
    `}
`;

export const TakePhotoButton = styled.button<{ $cameraSide: CameraSide }>`
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.1)};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 4rem;
  outline: none;
  padding: 1.25rem;
  top: 75%;
  transition: all 0.15s ease-in-out;
  width: 4rem;
  -webkit-tap-highlight-color: transparent;
  ${commonPositions};
  :hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.05)};
    box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
    svg {
      color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
    }
  }
  ${({ $cameraSide }) =>
    $cameraSide &&
    $cameraSide === 'selfie' &&
    css`
      top: 80%;
    `}
`;

export const NotificationText = styled.p`
  ${text};
  line-height: 1rem;
  text-align: left;
`;

export const Button = styled(DefaultButton)`
  background: transparent;
  color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
  left: 0;
  position: fixed;
  top: 0;
  ${from.mobileXL`
    left: 1rem;
    top: 1rem;
  `};
`;

export const ToMobileButton = styled(DefaultButton)`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: none;
`;

export const LightBulbIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const LoadingVideoContainer = styled.div<{ $cameraSide: CameraSide }>`
  ${absoluteCenter};
  ${landscapeRatio};
  svg {
    margin-bottom: 1rem;
  }
  ${({ $cameraSide }) =>
    $cameraSide &&
    $cameraSide === 'selfie' &&
    css`
      ${portraitRatio};
    `}
`;

export const CameraDisabledIcon = styled(CameraDisabled)`
  ${absoluteCenter};
  height: 4.8125rem;
  width: 5.90625rem;
`;

export const VideoCameraIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const AlloyWebCamAsBackground = styled(Webcam)<{
  device: string;
  $selfie: boolean;
}>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.25;
  position: absolute;
  filter: blur(0.25rem);

  ${({ device, $selfie }) =>
    (device === 'desktop' || $selfie) &&
    css`
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    `};
`;

export const AlloyWebCam = styled(Webcam)<{
  device: string;
  $documentType: DocumentType;
  $cameraSide: CameraSide;
}>`
  ${absoluteCenter};
  border-radius: 1rem;
  object-fit: cover;
  ${({ $documentType, $cameraSide }) =>
    ($documentType === 'license' || $documentType === 'passport') &&
    $cameraSide !== 'selfie' &&
    css`
      ${landscapeRatio};
    `}

  ${({ device }) =>
    device &&
    device === 'desktop' &&
    css`
      transform: translate(-50%, -50%) scaleX(-1);
      -webkit-transform: translate(-50%, -50%) scaleX(-1);
    `}

  ${({ $cameraSide, device }) =>
    ($cameraSide === 'selfie' &&
      device !== 'desktop' &&
      css`
        top: 45%;
        transform: translate(-50%, -45%) scaleX(-1);
        -webkit-transform: translate(-50%, -45%) scaleX(-1);
        height: 55%;
        width: 80%;
      `) ||
    ($cameraSide === 'selfie' &&
      css`
        height: 50%;
        width: 80%;
        ${from.mobile`
          height: 50%;
          width: 17rem
        `};
      `)}
`;

export const TakePhotoIcon = styled(DefaultTakePhotoIcon)`
  color: ${({ theme }) => theme.colors.white};
  height: 1.25rem;
  width: 1.375rem;
`;

export const DropZone = styled.div`
  align-items: center;
  border-radius: 1rem;
  border: dashed 1px ${({ theme }) => theme.colors.shadow};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  outline: none;
  transition: 0.3s;
  &:hover {
    background: ${({ theme }) => addAlpha(theme.colors.primary, 0.2)};
  }
`;

export const DragAndDropLabel = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  max-width: 10rem;
  text-align: center;
`;

export const UploadIcon = styled(FontAwesomeIcon)`
  height: 1.5rem;
  width: 1.5rem !important;
  color: ${({ theme }) => theme.colors.white};
`;

export const TimesIcon = styled(DefaultTimesIcon)`
  border-radius: 50%;
  height: 1.5rem;
  padding: 1rem;
  transition: background-color 0.25s ease-in-out;
  width: 1.5rem;
  &:hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.15)};
  }
`;
