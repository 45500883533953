export type IframeInfo = {
  documentTokenFront: string;
  documentTokenBack: string;
  evaluationToken: string;
  entityToken: string;
  outcome: string;
};

export const defaultIframeInfo: IframeInfo = {
  documentTokenFront: '',
  documentTokenBack: '',
  evaluationToken: '',
  entityToken: '',
  outcome: '',
};
