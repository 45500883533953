import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 80 80" {...props}>
    {children}
    <g stroke="#EE6767" strokeWidth="6" fill="none" fillRule="evenodd">
      <circle cx="40" cy="40" r="37" />
      <path strokeLinecap="round" d="M53 54L27 27M27 54l26-27" />
    </g>
  </svg>
));
