import { defaultPicture, Picture, IframeInfo, DocumentType } from 'model';

export type State = {
  dataLoaded: boolean;
  access_token: string;
  sessionId: string;
  clientKey: string;
  entityToken: string;
  externalEntityId: string;
  evaluationData: EvaluationData;
  documentTokenFront: string;
  documentTokenBack: string;
  evaluationToken: string;
  pictureFront: Picture;
  pictureBack: Picture;
  mobileFlowInProgress: boolean;
  iframeInfo: IframeInfo;
  clientInfo: ClientInfo;
  productionMode?: boolean;
  currentEvaluationTry: number;
  maxEvaluationAttempts: number;
  allowedDocumentTypes: string[];
  currentDocumentType: DocumentType;
  requiredSelfie?: boolean;
  selfieToken?: string;
  selfieInProgress: boolean;
  selfieImage?: string;
};

export const initialState: State = {
  dataLoaded: false,
  access_token: '',
  sessionId: '',
  clientKey: '',
  entityToken: '',
  externalEntityId: '',
  evaluationData: {},
  documentTokenFront: '',
  documentTokenBack: '',
  evaluationToken: '',
  pictureFront: defaultPicture,
  pictureBack: defaultPicture,
  mobileFlowInProgress: false,
  iframeInfo: {
    documentTokenFront: '',
    documentTokenBack: '',
    evaluationToken: '',
    entityToken: '',
    outcome: '',
  },
  clientInfo: {
    name: '',
    domain: '',
    logo_left: '',
    logo_right: '',
    set: false,
  },
  productionMode: false,
  currentEvaluationTry: 1,
  maxEvaluationAttempts: 2,
  allowedDocumentTypes: ['license'],
  currentDocumentType: 'license',
  requiredSelfie: undefined,
  selfieToken: '',
  selfieInProgress: false,
  selfieImage: undefined,
};

type ClientInfo = {
  name: string;
  domain: string;
  logo_left: string;
  logo_right: string;
  set: boolean;
};

export type EvaluationData = {
  nameFirst?: string;
  nameLast?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressPostalCode?: string;
  addressCountryCode?: string;
  birthDate?: string;
};

type SetAccessToken = {
  type: 'setAccessToken';
  payload: string;
};

type SetSessionId = {
  type: 'setSessionId';
  payload: string;
};

type NewPictureFront = {
  type: 'newPictureFront';
  payload: Picture;
};

type NewPictureBack = {
  type: 'newPictureBack';
  payload: Picture;
};

type SetEntityToken = {
  type: 'setEntityToken';
  payload: string;
};

type SetExternalEntityId = {
  type: 'setExternalEntityId';
  payload: string;
};

type SetDocumentTokenFront = {
  type: 'setDocumentTokenFront';
  payload: string;
};

type SetDocumentTokenBack = {
  type: 'setDocumentTokenBack';
  payload: string;
};

type SetMobileFlowInProgress = {
  type: 'setMobileFlowInProgress';
  payload: boolean;
};

type SetIframeInfo = {
  type: 'setIframeInfo';
  payload: IframeInfo;
};

type SetEvaluationData = {
  type: 'setEvaluationData';
  payload: EvaluationData;
};

type ResetContext = {
  type: 'resetContext';
};

type SetClientInfo = {
  type: 'setClientInfo';
  payload: ClientInfo;
};

type SetClientKey = {
  type: 'setClientKey';
  payload: string;
};

type ToggleProductionMode = {
  type: 'toggleProductionMode';
  payload: boolean;
};

type SetMaxEvaluationAttempts = {
  type: 'setMaxEvaluationAttempts';
  payload: number;
};

type SetCurrentEvaluationTry = {
  type: 'setCurrentEvaluationTry';
  payload: number;
};

type SetAllowedDocumentTypes = {
  type: 'setAllowedDocumentTypes';
  payload: string[];
};

type SetCurrentDocumentType = {
  type: 'setCurrentDocumentType';
  payload: DocumentType;
};

type SetRequiredSelfie = {
  type: 'setRequiredSelfie';
  payload: boolean;
};

type SetSelfieToken = {
  type: 'setSelfieToken';
  payload: string;
};

type SetSelfieImage = {
  type: 'setSelfieImage';
  payload: string;
};

type SetDataLoaded = {
  type: 'setDataLoaded';
  payload: boolean;
};

type SetSelfieInProgress = {
  type: 'setSelfieInProgress';
  payload: boolean;
};

export type Action =
  | SetAccessToken
  | SetSessionId
  | SetClientKey
  | NewPictureFront
  | NewPictureBack
  | SetEntityToken
  | SetExternalEntityId
  | SetDocumentTokenFront
  | SetDocumentTokenBack
  | SetMobileFlowInProgress
  | SetIframeInfo
  | SetEvaluationData
  | ResetContext
  | SetClientInfo
  | ToggleProductionMode
  | SetMaxEvaluationAttempts
  | SetCurrentEvaluationTry
  | SetAllowedDocumentTypes
  | SetCurrentDocumentType
  | SetRequiredSelfie
  | SetSelfieToken
  | SetDataLoaded
  | SetSelfieInProgress
  | SetSelfieImage;
