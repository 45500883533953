import { DocumentType } from 'model';
import { useCallback, useContext, useState } from 'react';
import { ContextOne } from 'context';
import { useHistory } from 'react-router';
import {
  exampleScanExplanation,
  licenseScanExplanation,
  passportScanExplanation,
  pictureTakingFinishedLabel,
} from './constants';

function useLogic(
  pictureTakingFinished: boolean,
  documentType: DocumentType,
  example?: boolean,
) {
  const [showSelfie, setShowSelfie] = useState(false);
  const { push } = useHistory();
  const { state } = useContext(ContextOne);
  const {
    requiredSelfie,
    selfieToken,
    currentDocumentType,
    selfieInProgress,
    pictureFront,
    pictureBack,
    selfieImage,
  } = state;

  function buildLayoutTitle(): string {
    if (example) {
      return 'Document example';
    }
    if (pictureTakingFinished) {
      return 'Pictures taken!';
    }
    return `Scan your ${documentType}`;
  }

  function buildExplanation(): string {
    if (example) {
      return exampleScanExplanation;
    }
    if (pictureTakingFinished) {
      return pictureTakingFinishedLabel;
    }
    if (documentType === 'passport') {
      return passportScanExplanation;
    }
    if (documentType === 'license') {
      return licenseScanExplanation;
    }
    return pictureTakingFinishedLabel;
  }

  const onHandleRequiredSelfie = useCallback(async () => {
    return push(`/selfie`);
  }, [push]);

  const onToggleSelfiePreview = useCallback(() => {
    setShowSelfie(!showSelfie);
  }, [showSelfie]);

  return {
    buildLayoutTitle,
    buildExplanation,
    requiredSelfie,
    selfieToken,
    onHandleRequiredSelfie,
    currentDocumentType,
    selfieInProgress,
    pictureFront,
    pictureBack,
    selfieImage,
    onToggleSelfiePreview,
    showSelfie,
  };
}
export default useLogic;
