import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import DefaultButton from 'components/Button';
import { from } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 0 0 1rem 0;
  ${from.tablet`
    padding: 0 1.25rem 2.5rem;
  `};
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.overlay};
  height: 0.75rem;
  margin-right: 0.8125rem;
  width: 0.75rem;
`;

export const Button = styled(DefaultButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.overlay};
  display: flex;
`;
