import React, { FC } from 'react';
import {
  Button,
  Container,
  LogoContainer,
  LogoLeft,
  TimesIcon,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const Header: FC<Props> = ({ enabled, logo, landing = false, ...rest }) => {
  const { fromMobile, logoLeft, renderRightLogo } = useLogic(landing);

  return (
    <Container $darkMode={landing} show={enabled} {...rest}>
      <LogoContainer>
        {logoLeft && !landing && <LogoLeft src={logoLeft} alt="logoLeft" />}
        {renderRightLogo()}
      </LogoContainer>
      {!fromMobile && (
        <Button
          $darkMode={landing}
          variant="link"
          onClick={() => window.parent.postMessage('close', '*')}
        >
          <TimesIcon $darkMode={!landing} />
        </Button>
      )}
    </Container>
  );
};

export default Header;
