import React, { FC } from 'react';
import { ButtonDefault, Link } from './styles';
import { Props } from './types';

const Button: FC<Props> = ({
  children,
  className,
  to,
  variant = 'primary',
  ...rest
}) =>
  to ? (
    <Link variant={variant} className={className} to={to}>
      {children}
    </Link>
  ) : (
    <ButtonDefault className={className} variant={variant} {...rest}>
      {children}
    </ButtonDefault>
  );

export default Button;
