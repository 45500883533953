import React, { FC } from 'react';
import { Container, Content, DeniedIcon, Title } from './styles';

const Expired: FC = () => {
  return (
    <Container>
      <Content>
        <DeniedIcon />
        <Title>
          We&apos;re sorry but it seems that link has been already used
        </Title>
      </Content>
    </Container>
  );
};

export default Expired;
