import styled, { css } from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultTag from 'components/Tag';
import { DefaultCardBack, DefaultCardFront } from 'components/Vectors';
import addAlpha from 'utils/addAlpha';
import { DocumentType } from 'model';

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Card = styled.img<{
  valid?: boolean | null;
  firstRender?: boolean;
  $documentType: DocumentType;
}>`
  border: 0.125rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
  height: 10rem;
  object-fit: cover;
  position: relative;
  transition: all ease 0.2s;
  width: 15.8125rem;

  ${({ valid, firstRender }) =>
    (valid &&
      !firstRender &&
      css`
          border: 0.125rem solid ${({ theme }) => theme.colors.success};
          border-radius: 0.75rem;
        }
    `) ||
    (!valid &&
      !firstRender &&
      css`
        border-radius: 0.75rem;
      `)}
`;

export const Button = styled(DefaultButton)`
  box-shadow: 0 0.625rem 1.25rem -0.3125rem ${({ theme }) => addAlpha(theme.colors.primary, 0.3)};
  :disabled {
    box-shadow: 0 0.625rem 1.25rem -0.3125rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
  }
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 11rem;
`;

export const Tag = styled(DefaultTag)`
  bottom: 1rem;
  position: absolute;
  right: 1rem;
`;

const cardDefault = css`
  border: 0.0625rem solid ${({ theme }) => addAlpha(theme.colors.primary, 0.1)};
  border-radius: 0.8rem;
  box-shadow: 0 0.625rem 1.25rem -0.375rem ${({ theme }) => addAlpha(theme.colors.black, 0.15)};
  height: 10rem;
  width: 15.9375rem;
`;

export const BackCardIcon = styled(DefaultCardBack)`
  ${cardDefault};
`;

export const FrontCardIcon = styled(DefaultCardFront)`
  ${cardDefault};
`;
