import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import addAlpha from 'utils/addAlpha';
import { TimesIcon as DefaultTimesIcon } from 'components/Vectors';
import DefaultButton from 'components/Button';
import Logo from 'components/Logo';

export const Container = styled.nav<{ $darkMode: boolean; show: boolean }>`
  align-items: center;
  background-color: ${({ theme, $darkMode }) =>
    $darkMode ? theme.colors.dark : theme.colors.white};
  border-bottom: 0.0625rem solid
    ${({ theme, $darkMode }) =>
      $darkMode
        ? addAlpha(theme.colors.white, 0.1)
        : addAlpha(theme.colors.dark, 0.1)};
  display: none;
  height: 1.75rem;
  min-height: 1.75rem;
  padding: 1.125rem 1.5rem 1.125rem 1.25rem;

  ${from.tablet`
     height: 2.25rem;
     min-height: 2.25rem;
  `};

  ${({ show }) =>
    show &&
    css`
      display: flex;
    `};
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 1.25rem;
`;

export const LogoRight = styled.img`
  height: 3rem;
  margin-left: auto;
`;

export const LogoLeft = styled.img`
  height: 3rem;
`;

export const AlloyLogo = styled(Logo)`
  margin-left: auto;
`;

export const Button = styled(DefaultButton)<{ $darkMode: boolean }>`
  border-left: 0.0625rem solid
    ${({ theme, $darkMode }) =>
      $darkMode
        ? addAlpha(theme.colors.white, 0.1)
        : addAlpha(theme.colors.dark, 0.1)};
  height: 100%;
  line-height: unset;
  margin-left: auto;
  padding-left: 1.25rem;
`;

export const TimesIcon = styled(DefaultTimesIcon)<{ $darkMode: boolean }>`
  color: ${({ theme, $darkMode }) =>
    $darkMode ? theme.colors.primary : theme.colors.white};
  height: 100%;
  width: 1.125rem;
`;
