import styled, { css } from 'styled-components';
import { LogoIcon as DefaultLogoIcon } from 'components/Vectors';
import { Text } from 'components/Vectors/Logo/styles';

const LogoIcon = styled(DefaultLogoIcon)`
  height: 3rem;

  ${({ mode }) =>
    mode === 'dark' &&
    css`
      ${Text} {
        color: ${({ theme }) => theme.colors.logoDark};
      }
    `};
`;

export default LogoIcon;
