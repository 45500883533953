import { useContext } from 'react';
import { ContextOne } from 'context';
import { TagType } from 'components/Tag/types';
import { Props } from './types';

function useLogic(picture: Props['picture'], side: Props['side']) {
  const { state } = useContext(ContextOne);
  const { pictureFront, pictureBack } = state;
  let tagType: TagType;

  switch (side) {
    default:
    case 'front':
      if (!pictureFront.evaluationInProgress) {
        tagType = picture?.valid ? 'accepted' : 'rejected';
      } else {
        tagType = 'checking';
      }
      break;
    case 'back':
      if (!pictureBack.evaluationInProgress) {
        tagType = picture?.valid ? 'accepted' : 'rejected';
      } else {
        tagType = 'checking';
      }
      break;
  }

  return {
    pictureFront,
    tagType,
  };
}

export default useLogic;
