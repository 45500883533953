export type Picture = {
  url?: string;
  valid?: boolean | null;
  outcomeReason?: string;
  evaluationInProgress?: boolean;
};

export const defaultPicture: Picture = {
  url: '',
  valid: undefined,
  outcomeReason: '',
  evaluationInProgress: false,
};
