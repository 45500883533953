import styled from 'styled-components';
import { container, from, text, title } from 'styles/mixins';
import DefaultButton from 'components/Button';
import {
  PassportIcon as DefaultPassportIcon,
  LicenseIcon as DefaultLicenseIcon,
} from 'components/Vectors';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3rem;
`;

export const Title = styled.h3`
  ${title};
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  ${text};
  max-width: 28rem;
`;

export const DocumentSelectorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  max-width: 28rem;
  > * + * {
    margin-top: 1rem;
  }

  ${from.tablet`
    max-width: 100%;
    > * + * {
      margin-left: 1.5rem;
      margin-top: 0;
    }
  `};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto;
  ${from.tablet`
    margin: auto;
  `};
`;

export const Button = styled(DefaultButton)``;

export const PassportIcon = styled(DefaultPassportIcon)`
  height: 2.5rem;
  width: 1.875rem;
`;

export const LicenseIcon = styled(DefaultLicenseIcon)`
  height: 1.875rem;
  width: 2.5rem;
`;
