import { RouteComponentProps } from 'react-router';

export const EvaluationOutcome = {
  approved: 'approved',
  denied: 'denied',
  retakeImages: 'retake images',
  manualReview: 'manual review',
};

export interface Props extends RouteComponentProps {
  webSocket?: SocketIOClient.Socket;
}
