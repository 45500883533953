import styled, { css } from 'styled-components';
import DefaultButton from 'components/Button';
import { container, from, text } from 'styles/mixins';
import { Failure, MobileScan, Success } from 'components/Vectors';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  ${container};
`;

export const FlowInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${from.tablet`justify-content: center;`};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const defaultInfo = css`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
`;

export const HelpContent = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  display: none;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.625rem 2.75rem 2rem;

  ${from.tablet`
    display: flex;
  `};

  svg {
    flex-shrink: 0;
  }
`;

const exampleOrFinishedContent = css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const HelpTextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 2.25rem;
`;

export const HelpTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.3125rem;
  margin: 0 0 0.5rem;
`;

export const HelpText = styled.p`
  ${text};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
`;

export const HelpButton = styled(DefaultButton)`
  margin-left: auto;
`;

export const FinishedContent = styled.div`
  ${exampleOrFinishedContent};
  margin: auto auto 3rem;
`;

export const Button = styled(DefaultButton)``;

export const OutputContent = styled.div`
  ${defaultInfo};
  flex: 1;
  max-width: 15rem;
  padding: 4.5rem 0 6rem;

  button {
    margin-top: auto;
  }
`;

export const SuccessIcon = styled(Success)`
  height: 5rem;
  margin-bottom: 2rem;
  width: 5rem;
`;

export const FailureIcon = styled(Failure)`
  height: 5rem;
  margin-bottom: 2rem;
  width: 5rem;
`;

export const MobileScanIcon = styled(MobileScan)`
  height: 4rem;
  width: 3.75rem;
`;

export const Text = styled.p<{ example?: boolean }>`
  ${text};
  color: ${({ theme }) => theme.colors.black};
  max-width: 19rem;

  ${({ example }) =>
    example &&
    css`
      ${from.tablet`
        margin-bottom: 4rem;
      `};
      margin-bottom: 1rem;
    `};
`;
