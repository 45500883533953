import { useCallback, useContext, useEffect, useState } from 'react';
import Api from 'API';
import { ContextOne } from 'context';
import { SmsData, UrlShorterReturnBody } from 'model/SmsLink';
import queryString from 'query-string';

function useLogic() {
  const { state } = useContext(ContextOne);
  const {
    access_token,
    sessionId,
    entityToken,
    clientKey,
    externalEntityId,
    evaluationData,
    mobileFlowInProgress,
    productionMode,
    maxEvaluationAttempts,
    currentDocumentType,
    requiredSelfie,
  } = state;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState<undefined | boolean>(undefined);
  const smsData: SmsData = { smsBody: '', phoneNumber: '' };
  const [mobileFlowEnabled, setMobileFlowEnabled] = useState(false);

  const handleChange = useCallback(phone => {
    setPhoneNumber(phone);
  }, []);

  const handleFormReset = useCallback(() => {
    setEmailSent(undefined);
    setPhoneNumber('');
  }, []);

  const handleSubmit = useCallback(
    async e => {
      let shortenedUrl: UrlShorterReturnBody = {};
      setLoading(true);
      e.preventDefault();
      smsData.phoneNumber = phoneNumber.replace(/\s/g, '');
      const url = `${
        process.env.REACT_APP_URL
      }/step-2?from=${sessionId}&key=${clientKey}${
        entityToken ? `&entityToken=${entityToken}` : ''
      }${
        externalEntityId ? `&externalEntityId=${externalEntityId}` : ''
      }&${queryString.stringify(evaluationData)}&access_token=${access_token}${
        productionMode ? '&production=true' : ''
      }&maxEvaluationAttempts=${maxEvaluationAttempts}&currentDocumentType=${currentDocumentType}${
        requiredSelfie ? '&selfie=true' : ''
      }`;

      shortenedUrl = await Api.shortenUrl(
        {
          urlToShorten: url,
        },
        access_token,
      );

      smsData.smsBody = `Here's your Alloy link:\n\n${shortenedUrl.link}`;
      const PostSms = await Api.sendSmsLink(smsData, access_token);

      if (PostSms.message !== undefined && PostSms.message !== '') {
        setErrorMessage(PostSms.message);
        setEmailSent(false);
      } else {
        setErrorMessage('');
        setEmailSent(true);
      }

      setLoading(false);
    },
    [
      access_token,
      clientKey,
      phoneNumber,
      smsData,
      sessionId,
      entityToken,
      externalEntityId,
      evaluationData,
      productionMode,
      maxEvaluationAttempts,
      currentDocumentType,
      requiredSelfie,
    ],
  );

  const handlePhoneNumberChange = useCallback(
    evt => {
      handleChange(evt);
    },
    [handleChange],
  );

  const handlePhoneNumberFormReset = useCallback(() => {
    handleFormReset();
  }, [handleFormReset]);

  const handlePhoneNumberFormSubmit = useCallback(
    e => {
      handleSubmit(e).then();
    },
    [handleSubmit],
  );

  const handleLoad = useCallback(() => {
    if (mobileFlowEnabled !== mobileFlowInProgress) {
      setMobileFlowEnabled(mobileFlowInProgress);
    }
  }, [mobileFlowEnabled, mobileFlowInProgress]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return {
    phoneNumber,
    loading,
    emailSent,
    errorMessage,
    handleChange,
    handleFormReset,
    handleSubmit,
    handlePhoneNumberChange,
    handlePhoneNumberFormReset,
    handlePhoneNumberFormSubmit,
    mobileFlowEnabled,
  };
}

export default useLogic;
