import React, { FC } from 'react';
import { Props } from './types';
import { Container, Content, Input, Label, CheckIcon } from './styles';

const RadioButton: FC<Props> = ({
  id,
  value,
  label,
  name,
  active,
  onChange,
  children,
  ...rest
}) => {
  return (
    <Container htmlFor={id} active={active} {...rest}>
      {children}
      <Content>
        <Label>{label}</Label>
        <Input
          id={id}
          value={value}
          type="radio"
          name={name}
          onChange={onChange}
        />
        {active && <CheckIcon />}
      </Content>
    </Container>
  );
};

export default RadioButton;
