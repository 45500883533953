import React from 'react';
import accessibleSvg from 'utils/accesibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 148 148" {...props}>
    {children}
    <g fill="none" fillRule="evenodd">
      <rect
        fill="#FAB72D"
        fillRule="nonzero"
        opacity=".075"
        width="148"
        height="148"
        rx="74"
      />
      <rect
        fill="#FAB72D"
        fillRule="nonzero"
        opacity=".075"
        x="19"
        y="19"
        width="110"
        height="110"
        rx="55"
      />
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        fill="#000"
        fillRule="nonzero"
        transform="translate(37 37)"
      />
      <circle
        cx="37.5"
        cy="37.5"
        r="37.5"
        fill="#FAB72D"
        transform="translate(37 37)"
      />
      <g fill="#FFF">
        <g transform="translate(67 50)">
          <ellipse cx="8" cy="42.241" rx="4.444" ry="4.31" />
          <path d="M5.614 3.448h4.772a2 2 0 012 2.058L11.61 32.54a2 2 0 01-1.999 1.943H6.388a2 2 0 01-2-1.943L3.615 5.506a2 2 0 012-2.058z" />
        </g>
      </g>
    </g>
  </svg>
));
